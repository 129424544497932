import React, { useEffect, useState, ChangeEvent } from 'react';

import {
    Box,
    Typography,
    TextField,
    Button,
    Card,
    CardMedia,
} from '@mui/material';

import { firestore, storage } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';

import { useSnackbar } from '../../Context/SnackbarContext';

// Define the types for the form data sections
interface ImageData {
    previewUrl: string | null;
    filePath: string | null;
    file: File | null;
}

interface SectionData {
    text: string;
    images: ImageData[];
}

interface FormData {
    [key: string]: SectionData;
}

// Utility function to get section titles
const getSectionTitle = (sectionKey: string): string => {
    const titles: { [key: string]: string } = {
        whatWeDo: '何をやっているのか？',
        whyWeDoIt: 'なぜやっているのか？',
        howWeDoIt: 'どうやってやっているのか？',
    };
    return titles[sectionKey] || '未知のセクション';
};

interface SectionEditFormProps {
    sectionKey: string;
    formData: FormData;
    onTextChange: (sectionKey: string, newText: string) => void;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
    companyId: string;
    hasEditPermission: boolean;
}


const SectionEditForm: React.FC<SectionEditFormProps> = ({
    sectionKey,
    formData,
    onTextChange,
    setFormData,
    companyId,
    hasEditPermission,
}) => {
    const sectionData = formData[sectionKey];
    const { showSnackbar } = useSnackbar();
    const [text, setText] = useState<string>(formData[sectionKey]?.text || '');

    useEffect(() => {
        setText(sectionData.text);
    }, [sectionData]);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const updateImagesState = (newImages: ImageData[]) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [sectionKey]: {
                ...prevFormData[sectionKey],
                images: newImages,
            },
        }));
    };

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.files && event.target.files[0]) {
            const newFile = event.target.files[0];
            const newPreviewUrl = URL.createObjectURL(newFile);

            const newImages = [...sectionData.images];
            newImages[index] = { file: newFile, previewUrl: newPreviewUrl, filePath: null };
            updateImagesState(newImages);
        }
    };

    const handleImageRemove = async (index: number) => {
        const imageToRemove = sectionData.images[index];

        // Ensure filePath exists before trying to delete
        if (imageToRemove.filePath) {
            const imageRef = ref(storage, imageToRemove.filePath);
            try {
                await deleteObject(imageRef);

                const newImages = [...sectionData.images];
                newImages[index] = { file: null, previewUrl: null, filePath: null };

                const updateData = {
                    [`${sectionKey}.images`]: newImages.map((img) => ({
                        previewUrl: img.previewUrl || null,
                        filePath: img.filePath || null,
                    })),
                };

                await updateDoc(doc(firestore, 'companies', companyId), updateData);
                updateImagesState(newImages);
                showSnackbar({ message: '画像が削除されました', type: 'success' });
            } catch (error) {
                if ((error as any).code === 'storage/object-not-found') {
                    showSnackbar({ message: '削除対象のファイルが存在しません', type: 'error' });
                } else {
                    showSnackbar({ message: '画像削除に失敗しました', type: 'error' });
                }
            }
        } else {
            showSnackbar({ message: '画像の削除またはドキュメントの更新に失敗しました', type: 'error' });
        }
    };


    return (
        <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                {getSectionTitle(sectionKey)}
            </Typography>

            <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                {sectionData.images.map((image, index) => (
                    <React.Fragment key={index}>
                        <Box sx={{ width: '50%', display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                            {image.previewUrl ? (
                                <Card sx={{ width: '100%', maxHeight: 'auto', overflow: 'hidden' }}>
                                    <CardMedia
                                        component="img"
                                        image={image.previewUrl}
                                        alt={`プレビュー ${index + 1}`}
                                        sx={{
                                            height: 'auto',
                                            objectFit: 'cover',
                                            width: '100%',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </Card>
                            ) : (
                                <Card sx={{ width: '100%', maxHeight: 'auto', overflow: 'hidden' }}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            height: 'auto',
                                            objectFit: 'cover',
                                            width: '100%',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </Card>
                            )}
                            {hasEditPermission && (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleImageChange(e, index)}
                                        style={{ display: 'none' }}
                                        id={`image-upload-${sectionKey}-${index}`}
                                    />
                                    {image.previewUrl ? (
                                        <>
                                            <label htmlFor={`image-upload-${sectionKey}-${index}`}>
                                                <Button component="span" variant="contained">
                                                    画像変更
                                                </Button>
                                            </label>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => handleImageRemove(index)}
                                            >
                                                削除
                                            </Button>
                                        </>
                                    ) : (
                                        <label htmlFor={`image-upload-${sectionKey}-${index}`}>
                                            <Button component="span" variant="contained">
                                                画像アップロード
                                            </Button>
                                        </label>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </React.Fragment>
                ))}
            </Box>

            {hasEditPermission ? (
                <TextField
                    fullWidth
                    label={getSectionTitle(sectionKey)}
                    variant="outlined"
                    value={text}
                    onChange={handleTextChange}
                    onBlur={() => onTextChange(sectionKey, text)}
                    sx={{ mb: 6 }}
                    multiline
                />
            ) : (
                <Typography variant="body1" sx={{ mb: 12 }}>
                    {sectionData.text || ''}
                </Typography>
            )}
        </Box>
    );
};

export default SectionEditForm;