// helpers/dateHelper.ts
export const calculateDaysInMonth = (year: number, month: number): number[] => {
    const days = new Date(year, month, 0).getDate();
    return Array.from({ length: days }, (_, i) => i + 1);
};

export const formatDate = (date?: { seconds: number }) => {
    if (!date) return '';
    const d = new Date(date.seconds * 1000);
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
};