// React とその Hooks
import React from 'react';

// Material-UI Components & Icons
import { Box, Typography, Button, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Timeline,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineItem,
    timelineOppositeContentClasses,
    timelineItemClasses
} from '@mui/lab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';

import SaveOnBlurTextField from '../components/Molecules/SaveOnBlurTextField';
import CustomTimeline from '../components/Organisms/CustomTimeline';
import HistoryEditDialog from '../components/Organisms/HistoryEditDialog';
import ProfileTopNavigationContent from '../components/Organisms/ProfileTopNavigationContent';
import HighlightCardDialog from '../components/Organisms/HighlightCardDialog';
import CertificationOrAward from '../components/Organisms/CertificationOrAward';
import NavigationBar from '../components/Organisms/NavigationBar';
import Footer from '../components/Organisms/Footer';
import ResponsiveContainer from '../components/Templates/ResponsiveContainer';

import { useProfile, useProfileModals } from '../Hooks/useProfile';

import { getInitialValue } from '../utils/profileHelper';

const Profile: React.FC = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const {
        user,
        timelineItems,
        isUser
    } = useProfile();

    const {
        modalState,
        handleSaveToFirestore,
        handleOpenModal,
        handleCloseModal,
        handleSaveHistoryData,
        handleSaveCertificationOrAward,
        handleDeleteCertificationOrAward
    } = useProfileModals(user?.documentId);

    const bottomSections = [
        { title: '資格', items: user?.certifications || [], type: 'certifications' },
        { title: '受賞', items: user?.awards || [], type: 'awards' },
    ];

    return (
        <>
            <NavigationBar />
            <ProfileTopNavigationContent user={user} />
            <ResponsiveContainer>
                {isUser ? (
                    <SaveOnBlurTextField
                        initialValue={getInitialValue(user, 'catchphrase', 'キャッチコピーや好きな言葉')}
                        onSave={(value: any) => handleSaveToFirestore('catchphrase', value)}
                        TypographyProps={{
                            variant: "h3",
                            sx: { fontWeight: "bold" }
                        }}
                        isDisabled={!isUser} initialRows={1} maxRows={6} />
                ) : (
                    <Typography variant="h3" sx={{ fontWeight: 'bold', padding: 2 }}>
                        {user?.catchphrase || ''}
                    </Typography>
                )}
                {isUser ? (
                    <SaveOnBlurTextField
                        initialValue={getInitialValue(user, 'profile', '自己紹介')}
                        onSave={(value: any) => handleSaveToFirestore('profile', value)}
                        TypographyProps={{
                            variant: "body1",
                            sx: { mt: 2 }
                        }}
                        multiline
                        initialRows={4}
                        greyBackground
                        isDisabled={!isUser} maxRows={4} />
                ) : (
                    <Typography variant="body1" sx={{ mt: 2, p: 2 }}>
                        {user?.profile || ''}
                    </Typography>
                )}

                <Timeline sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0,
                        padding: 0,
                        paddingX: 2,
                    },
                    mt: 5
                }}>
                    <TimelineItem sx={{ borderBottom: '1px dashed #ccc' }}>
                        {isSm && (
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="white">
                                2024/03<br />
                                ~2023/10
                            </TimelineOppositeContent>
                        )}
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                                <UpdateIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold' }}>
                                    この先やってみたいこと
                                </Typography>
                            </Box>
                            <Typography variant="body1">
                                {isUser ? (
                                    <SaveOnBlurTextField
                                        initialValue={getInitialValue(user, 'goals', '(例)地域社会への貢献: 地域の建物や施設の設計や改修プロジェクトに参加し、地域社会に貢献する。')}
                                        onSave={(value: any) => handleSaveToFirestore('goals', value)}
                                        TypographyProps={{
                                            variant: "body1",
                                        }}
                                        multiline
                                        maxRows={6}
                                        greyBackground
                                        isDisabled={!isUser} initialRows={4} />
                                ) : (
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {user?.goals || ''}
                                    </Typography>
                                )}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    {isUser && (
                        <Box display="flex" alignItems="center" justifyContent='center' sx={{ mt: -2 }}>
                            <Button
                                onClick={() => {
                                    handleOpenModal({}, 'history')
                                }}
                                color="primary"
                                size='small'
                                startIcon={<AddCircleOutlineIcon />}
                                variant="contained"
                            >
                                職歴・学歴の追加
                            </Button>
                        </Box>
                    )}

                    <CustomTimeline items={timelineItems} isDisabled={!isUser} />
                </Timeline>
            </ResponsiveContainer>

            <ResponsiveContainer>
                {
                    bottomSections.map((section) => (
                        <CertificationOrAward
                            key={section.type}
                            title={section.title}
                            items={section.items}
                            onAdd={() => handleOpenModal({}, 'add', section.type)}
                            onEdit={(item: any) => handleOpenModal(item, 'edit', section.type)}
                            isUser={isUser}
                        />
                    ))
                }
            </ResponsiveContainer>

            <Footer />

            <HistoryEditDialog
                open={modalState.type === 'history' && modalState.open}
                onClose={handleCloseModal}
                onSave={(data: any) => handleSaveHistoryData(data)}
                initialData={modalState.data}
                isEdit={false}
                onDelete={undefined}
            />

            <HighlightCardDialog
                open={(modalState.type === 'edit' || modalState.type === 'add') && modalState.open}
                modalType={modalState.type!}
                handleClose={handleCloseModal}
                onSave={(data: any) => handleSaveCertificationOrAward(data, modalState.category!)}
                onDelete={(id: string) => handleDeleteCertificationOrAward(id, modalState.category!)}
                data={modalState.data}
                category={modalState.category!}
                isDisabled={isUser}
            />
        </>
    );
}

export default Profile;
