// 環境に応じたエンドポイントの設定
export const API_ENDPOINTS = {
    elasticSearch: "https://elasticsearch-frnrslgk3q-an.a.run.app",
    generateAiMessageFromIndex: 'https://generate-ai-message-from-index-frnrslgk3q-an.a.run.app',
    deleteBucketFiles: 'https://delete-bucket-files-frnrslgk3q-an.a.run.app',
    createCompanyProfileWithImage: 'https://create-company-profile-with-image-frnrslgk3q-an.a.run.app',
    scrapeAndSaveToStorage: 'https://scrape-and-save-to-storage-frnrslgk3q-an.a.run.app',
    fetchDivideAndStoreChunks: 'https://fetch-divide-and-store-chunks-frnrslgk3q-an.a.run.app',
    createIndexFromChunks: 'https://create-index-from-chunks-frnrslgk3q-an.a.run.app',
    // anotherAPI: process.env.REACT_APP_ANOTHER_API_ENDPOINT || "https://another-api.com",
    // 他のエンドポイントがあれば追加
};
