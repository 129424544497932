import { doc, setDoc } from 'firebase/firestore';
import { updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { firestore } from '../../firebase';

export const skipTutorial = async (userId: string) => {
  try {
    const userDocRef = doc(firestore, "users", userId);
    await setDoc(userDocRef, { tutorialStatus: 'skipped' }, { merge: true });
  } catch (error) {
    console.error('エラーが発生しました:', error);
  }
};

export const updateBookmark = async (userId: string, companyId: string, isBookmarked: boolean) => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
        await updateDoc(userDocRef, {
            companyBookMarks: isBookmarked ? arrayRemove(companyId) : arrayUnion(companyId),
        });
    } catch (error) {
        console.error('ブックマークの更新に失敗しました: ', error);
        throw new Error('ブックマークの更新に失敗しました');
    }
};