// services/firebase/chatService.ts
import { firestore } from '../../firebase';
import { collection, getDocs, query, where, addDoc, doc, serverTimestamp, updateDoc, getDoc, orderBy } from 'firebase/firestore';
import { ElasticFetchCompany, Company, User } from '../../types';
import { mailTemplate } from '../../utils/template';
import { GENERATE_AI_MESSAGE_FROM_INDEX } from '../../utils/apiEndpoint';

interface ChatData {
    participantIds: string[];
    participants: { userId: string; userType: string }[];
    companyId: string;
    companyRef: any;
    chatTag: string;
    avatarSrc?: string | null;
    companyDisplaySrc?: string | null;
    lastMessage: string;
    lastMessageTimestamp: any;
    archived: boolean;
    startedBy: { userId: string; userType: string };
    createdAt: any;
    updatedAt: any;
}

/**
 * ユーザーと企業ID、chatTagに基づいて既存のチャットを検索し、既存チャットがない場合は新しいチャットを作成します。
 * 
 * @async
 * @function startOrJoinChat
 * @param {Object} user - 現在のユーザーオブジェクト（uid、userTypeを含む）。
 * @param {string} companyId - チャットを関連付ける企業のID。
 * @param {string} chatTag - チャットのタグ。例: "AI担当者"。
 * @param {ElasticFetchCompany} company - 企業の情報を含むオブジェクト。
 * 
 * @returns {Promise<string>} - 既存のチャットがある場合はそのチャットIDを返し、なければ新しく作成されたチャットIDを返します。
 * 
 * @throws {Error} - Firebase Firestore操作が失敗した場合にエラーを投げます。
 * 
 * @example
 * const chatId = await startOrJoinChat(user, 'companyId123', 'AI担当者', company);
 * console.log(chatId); // チャットIDを出力
 */
export const startOrJoinChat = async (
    user: any, 
    companyId: string, 
    chatTag: string, 
    company: ElasticFetchCompany | Company
): Promise<string> => {
    const q = query(
        collection(firestore, 'chats'),
        where('participantIds', 'array-contains', user.uid),
        where('companyId', '==', companyId),
        where('chatTag', '==', chatTag),
        where('archived', '==', false)
    );

    const querySnapshot = await getDocs(q);

    // 既存チャットが存在する場合、そのチャットIDを返す
    if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        return firstDoc.id;
    } else {
        let participantIds: string[] = [];
        let participants: { userId: string; userType: string }[] = [];

        if (chatTag !== 'AI担当者') {
            const companyUsersQuery = query(
                collection(firestore, 'users'),
                where('userType', '==', 'company'),
                where('companyId', '==', companyId)
            );
            const usersSnapshot = await getDocs(companyUsersQuery);

            usersSnapshot.forEach((doc) => {
                participantIds.push(doc.id);
                participants.push({ userId: doc.id, userType: doc.data().userType });
            });
        }

        participantIds.push(user.uid);
        participants.push({ userId: user.uid, userType: user.userType });

        // 型ガード：ElasticFetchCompanyかCompanyかを確認
        const isElasticFetchCompany = (company: ElasticFetchCompany | Company): company is ElasticFetchCompany => {
            return (company as ElasticFetchCompany)._meta !== undefined;
        };

        const avatarSrc = isElasticFetchCompany(company)
            ? company?.iconurl?.raw ?? null  // ElasticFetchCompanyの場合
            : company?.iconUrl ?? null;      // Companyの場合

        const chatData: ChatData = {
            participantIds,
            participants,
            companyId: companyId,
            companyRef: doc(firestore, `companies/${companyId}`),
            chatTag,
            avatarSrc,
            companyDisplaySrc: user?.userIconUrl ?? null,
            lastMessage: '',
            lastMessageTimestamp: serverTimestamp(),
            archived: false,
            startedBy: { userId: user.uid, userType: user.userType },
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        };

        const docRef = await addDoc(collection(firestore, 'chats'), chatData);
        return docRef.id; // 新しいチャットのIDを返す
    }
};

export const addMessageToFirestore = async (chatId: string, messageText: string, userId: string) => {
    try {
        await addDoc(collection(firestore, `chats/${chatId}/messages`), {
            senderId: userId,
            text: messageText,
            timestamps: serverTimestamp(),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        });
    } catch (error) {
        console.error('Error adding message to Firestore:', error);
    }
};

export const addLastMessageToChat = async (chatId: string, messageText: string, userId:string) => {
    const chatRef = doc(firestore, 'chats', chatId);
    try {
        await updateDoc(chatRef, {
            lastMessage: messageText,
            lastMessageTimestamp: serverTimestamp(),
            addLastMessageBy: userId,
            updatedAt: serverTimestamp(),
        });
    } catch (error) {
        console.error('Error updating last message in chat:', error);
    }
}

export const callAiMessageEndpoint = async (text: string, companyId: string, companyName: string) => {
    try {
        const response = await fetch(GENERATE_AI_MESSAGE_FROM_INDEX, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text,
                company_id: companyId,
                company_name: companyName,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    } catch (error) {
        console.error('Error calling AI endpoint:', error);
        throw error;
    }
};

export const sendMailNotification = async (toUids: string[], activeChatRoomId: string) => {
    try {
        await addDoc(collection(firestore, 'mail'), {
            toUids,
            message: {
                subject: '【Ciel】メッセージが届いています',
                html: mailTemplate({ activeChatRoomId }), // Use the correct template
            },
        });
    } catch (error) {
        console.error('Error adding mail to Firestore:', error);
    }
};

export const markChatAsDeletedInFirestore = async (chatId: string) => {
    const chatRef = doc(firestore, 'chats', chatId);
    try {
        await updateDoc(chatRef, {
            archived: true,
        });
    } catch (error) {
        console.error('Error archiving chat:', error);
        throw error;
    }
};


export const getChatsQuery = (user: User, isArchived = false) => {
    if (user?.userType !== 'company') {
        return query(
            collection(firestore, 'chats'),
            where('participantIds', 'array-contains', user?.uid),
            where('archived', '==', isArchived),
            orderBy('updatedAt', 'desc')
        );
    } else {
        return query(
            collection(firestore, 'chats'),
            where('companyId', '==', user?.companyId),
            where('archived', '==', isArchived),
            where('chatTag', '!=', 'AI担当者'),
            orderBy('updatedAt', 'desc')
        );
    }
};

export const getCompanyDetails = async (companyId: string) => {
    const companyRef = doc(firestore, `companies/${companyId}`);
    const companySnap = await getDoc(companyRef);
    if (companySnap.exists()) {
        return companySnap.data();
    }
    return null;
};