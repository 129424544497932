import { useState, useEffect } from 'react';
import { getImageUrl } from '../services/firebase/getImageUrl';


/**
 * 画像のURLを取得するためのカスタムフック
 * 画面幅のサイズに応じて、適切な画像URLを取得する
 * 
 * @param {string} itemId - 企業のID
 * @param {boolean} isMobile - モバイルデバイスかどうかのフラグ
 * @returns {object} - 取得した画像URLとロード状態
 * 
 * @example
 * const { imageUrl, loading } = useFetchImageUrl(companyId, isMobile);
 */
const useFetchImageUrl = (itemId: string, isMobile: boolean) => {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        if (!itemId) return;

        // Define the image path based on the itemId and isMobile flag
        const imagePath = `company_images/${itemId}/${isMobile ? 'thumbnail_200x200' : 'thumbnail_400x400'}`;

        const fetchImageUrl = async () => {
            setLoading(true); // Set loading to true when fetching starts
            try {
                const url = await getImageUrl(imagePath);
                setImageUrl(url);
            } catch (error) {
                setImageUrl('https://sus-shokunin.com/wordpress/wp-content/uploads/2016/08/noimage.jpg')
            } finally {
                setLoading(false); // Set loading to false after fetching is complete
            }
        };

        fetchImageUrl();
    }, [itemId, isMobile]);

    return { imageUrl, loading }; // Return both imageUrl and loading
};

export default useFetchImageUrl;
