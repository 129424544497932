import React from 'react';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Item {
    title: string;
    date?: string;
}

interface CertificationOrAwardProps {
    title: string;
    items: Item[];
    onAdd: () => void;
    onEdit: (item: Item) => void;
    isUser: boolean;
}

const sortByDate = (a: Item, b: Item) => {
    // 日付が存在しない項目は、常に後ろに配置
    if (!a.date) return 1;
    if (!b.date) return -1;

    // 日付を比較して並び替え
    return new Date(b.date).getTime() - new Date(a.date).getTime();
};

const CertificationOrAward: React.FC<CertificationOrAwardProps> = ({ title, items, onAdd, onEdit, isUser }) => {
    return (
        <>
            <Divider sx={{ mt: 4, mb: 2 }} />
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mr: 3 }}>
                    {title}
                </Typography>
                {isUser && (
                    <Button
                        onClick={onAdd}
                        color="primary"
                        size='small'
                        startIcon={<AddCircleOutlineIcon />}
                        variant="contained"
                    >
                        追加
                    </Button>
                )}
            </Box>
            {items.length > 0 ? (
                items.sort(sortByDate).map((item, index) => (
                    <Paper
                        key={index}
                        sx={{
                            p: 2,
                            mt: 2,
                            transition: 'background-color 0.3s ease', // 背景色の変更にトランジションを適用
                            '&:hover': {
                                backgroundColor: 'action.hover', // ホバー時の背景色をMuiテーマのアクションホバーカラーに設定
                            },
                        }}
                        onClick={() => onEdit(item)}
                    >
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{item.title}</Typography>
                        <Typography variant="caption" color="text.secondary">{item.date ? item.date : "日付なし"}</Typography>
                    </Paper>
                ))
            ) : (
                <Typography variant="body1" color="text.secondary">
                    {title}が登録されていません。
                </Typography>
            )}
        </>
    );
};

export default CertificationOrAward;
