import React from 'react';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';

interface SaveLeftFloatBtnProps {
    handleSubmit: () => void;
    disabled: boolean;
}

const SaveLeftFloatButton: React.FC<SaveLeftFloatBtnProps> = ({ handleSubmit, disabled }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    // ビューポートの幅からコンテナの幅（md）を引いた差を計算して、右のマージンとして設定
    const marginRight = isMdUp ? `calc((100vw - 960px) / 2)` : theme.spacing(2);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: theme.spacing(2),
                right: marginRight,
                zIndex: 1000,
                mb: 6,
            }}
        >
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disabled}>
                保存
            </Button>
        </Box>
    );
};

export default SaveLeftFloatButton;