import { useEffect, useState } from 'react';
import { fetchCompanyInfo, fetchCompanyUsers, subscribeToAuthIds, updateCompanyInfo } from '../services/firebase/companyService';
interface AuthId {
    id: string;
    authId: string;
    role: string;
    usedCount: number;
}

export const useCompanyData = (companyId: string) => {
    const [companyInfo, setCompanyInfo] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [authIds, setAuthIds] = useState<AuthId[]>([]);
    const [companyUsers, setCompanyUsers] = useState<any[]>([]);
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const companyData = await fetchCompanyInfo(companyId);
                setCompanyInfo(companyData);
            } catch (err) {
                setError(err as Error);
            } finally {
                setIsLoading(false);
            }
        };

        if (companyId) {
            fetchData();
        }
    }, [companyId]);

    const updateCompany = async (updatedData: any) => {
        try {
            setIsLoading(true);
            await updateCompanyInfo(companyId, updatedData);
        } catch (err) {
            setError(err as Error);
        } finally {
            setIsLoading(false);
        }
    };

    // fetch users
    useEffect(() => {
        const fetchUsers = async () => {
            const companyUsers = await fetchCompanyUsers(companyId);
            setCompanyUsers(companyUsers);
        };

        if (companyId) {
            fetchUsers();
        }
    }, [companyId]);

    // fetch authIds
    useEffect(() => {
        if (!companyId) return;

        const unsubscribe = subscribeToAuthIds(companyId, setAuthIds);
        return () => unsubscribe();
    }, [companyId]);

    return {
        isLoading,
        error,
        companyInfo,
        updateCompany,
        authIds,
        companyUsers,
        isEnabled,
        setIsEnabled,
    };
};