import React from 'react';
import Container from '@mui/material/Container';

interface ResponsiveContainerProps {
    children: React.ReactNode;
}

const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({ children }) => {
    return (
        <Container maxWidth="md" sx={{ mt: { xs: 8, md: 12 }, mb: 4, px: { xs: 1, sm: 2, md: 3 } }}>
            {children}
        </Container >
    );
}

export default ResponsiveContainer;
