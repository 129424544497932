import React from 'react';
import {
    Box,
    Typography,
    Stack,
    TextField,
    Divider,
    IconButton,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery
} from '@mui/material';
import { 
    AddCircleOutline as AddCircleOutlineIcon, 
    CheckCircle as CheckCircleIcon, 
    ExpandMore as ExpandMoreIcon 
} from '@mui/icons-material';
import { useUser } from '../../Context/UserContext';

// TypeScriptでの型定義
interface SearchParams {
    searchText: string;
}

interface Feature {
    name: string;
    enabled: boolean;
}

interface SearchBarContentProps {
    searchParams: SearchParams;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSearch: () => void;
    toggleFeature: (featureName: string) => void;
    features: Feature[];
}

interface SearchBarProps {
    searchParams: SearchParams;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSearch: () => void;
    toggleFeature: (featureName: string) => void;
    features: Feature[];
}

// 検索バーコンポーネント
const SearchInput: React.FC<{ searchText: string, onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void }> = ({ searchText, onInputChange }) => (
    <TextField
        fullWidth
        label="キーワード検索"
        variant="outlined"
        size="small"
        name="searchText"
        value={searchText}
        onChange={onInputChange}
    />
);

// 特徴フィルタートグルコンポーネント
const FeatureToggle: React.FC<{ features: Feature[], toggleFeature: (featureName: string) => void, user: any }> = ({ features, toggleFeature, user }) => (
    <Stack spacing={0}>
        {features.map((feature) => (
            (user || feature.name !== "ブックマーク済み") && (
                <Box key={feature.name} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body2">{feature.name}</Typography>
                    <IconButton
                        color="primary"
                        aria-label="toggle feature"
                        size="small"
                        onClick={() => toggleFeature(feature.name)}
                    >
                        {feature.enabled ? <CheckCircleIcon /> : <AddCircleOutlineIcon />}
                    </IconButton>
                </Box>
            )
        ))}
    </Stack>
);

// 検索バーコンテンツコンポーネント
const SearchBarContent: React.FC<SearchBarContentProps> = ({ searchParams, handleInputChange, handleSearch, toggleFeature, features }) => {
    const { user } = useUser();
    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={2}>
                <SearchInput searchText={searchParams.searchText} onInputChange={handleInputChange} />
            </Stack>
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" color='text.secondary' sx={{ fontWeight: 'bold' }}>特徴</Typography>
                <Divider />
                <FeatureToggle features={features} toggleFeature={toggleFeature} user={user} />
            </Box>
            <Box sx={{ mt: 1 }}>
                <Button variant="contained" fullWidth onClick={handleSearch}>検索</Button>
            </Box>
        </Box>
    );
};

const CompanySearchBar: React.FC<SearchBarProps> = ({ searchParams, handleInputChange, handleSearch, toggleFeature, features }) => {
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    return (
        <Box>
            {isMobile ? (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="search-bar-content"
                        id="search-bar-header"
                    >
                        <Typography variant="subtitle1" color='text.secondary' sx={{ fontWeight: 'bold' }}>検索条件</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SearchBarContent
                            searchParams={searchParams}
                            handleInputChange={handleInputChange}
                            handleSearch={handleSearch}
                            toggleFeature={toggleFeature}
                            features={features}
                        />
                    </AccordionDetails>
                </Accordion>
            ) : (
                <SearchBarContent
                    searchParams={searchParams}
                    handleInputChange={handleInputChange}
                    handleSearch={handleSearch}
                    toggleFeature={toggleFeature}
                    features={features}
                />
            )}
        </Box>
    );
};

export default CompanySearchBar;