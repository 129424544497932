import React, { useState } from 'react';
// Router and hooks
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// MUI components
import {
    Button,
    Container,
    Link,
    TextField,
    Typography,
} from '@mui/material';

import { useAuth } from '../../Hooks/useAuth';

// Context
import { useSnackbar } from '../../Context/SnackbarContext';

// Components
import ResponsiveContainer from '../../components/Templates/ResponsiveContainer';
import PasswordResetDialog from '../../components/Organisms/PasswordResetDialog';
import NavigationBar from '../../components/Organisms/NavigationBar';
import Footer from '../../components/Organisms/Footer';

// 型定義
interface LoginFormInputs {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const [resetDialogOpen, setResetDialogOpen] = useState(false); // ダイアログの状態
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>({ mode: "onChange" });

    // 関数の取得
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const { error, login, signInWithLineProvider } = useAuth();

    // ログイン処理関数
    const handleLogin: SubmitHandler<LoginFormInputs> = async (data) => {
        const { email, password } = data;

        await login(
            email,
            password,
            (user) => {
                if (user?.companyId) {
                    // user.companyId が存在する場合、企業のダッシュボードにリダイレクト
                    navigate(`/company/${user.companyId}/dashboard`);
                } else {
                    // 通常のユーザーであればホームにリダイレクト
                    navigate('/');
                }
            }
        );

        if (error) {
            showSnackbar({ message: error, type: 'error', title: 'ログインエラー' });
        }
    };

    // LINEログイン処理
    async function handleOIDCLogin() {
        await signInWithLineProvider(() => {
            navigate('/');
        });
        if (error) {
            showSnackbar({ message: error, type: 'error', title: 'ログインエラー' });
        }
    }

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <Container component="main" maxWidth="xs" sx={{ minHeight: '55vh' }}>
                    <Typography component="h1" variant="h5">ログイン</Typography>
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="メールアドレス"
                            {...register("email", {
                                required: "メールアドレスを入力してください",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "無効なメールアドレスです"
                                }
                            })}
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="パスワード"
                            type="password"
                            {...register("password", {
                                required: "パスワードを入力してください",
                                minLength: {
                                    value: 6,
                                    message: "パスワードは6文字以上で入力してください"
                                }
                            })}
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                        />
                        <Button type="submit" fullWidth variant="contained" size="large" color="primary" sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2, fontWeight: 'bold' }}>ログイン</Button>
                    </form>
                    <Button
                        variant='contained'
                        onClick={handleOIDCLogin}
                        fullWidth
                        size="large"
                        sx={{
                            borderRadius: '8px',
                            paddingY: 1.5,
                            marginTop: 2,
                            backgroundColor: '#06C755',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: 'rgba(6, 199, 85, 0.9)',
                                boxShadow: 'none',
                            },
                            '&:active': {
                                backgroundColor: 'rgba(6, 199, 85, 0.7)',
                                boxShadow: 'none',
                            }
                        }}
                    >
                        LINEでログイン
                    </Button>
                    <Typography variant="body2" sx={{ mt: 2 }}>LINEログインは一般ユーザーのみ利用できます</Typography>
                    <Link component="button" variant="body2" onClick={() => setResetDialogOpen(true)} sx={{ mt: 2 }}>
                        パスワードをお忘れですか？
                    </Link>
                    <PasswordResetDialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)} />
                </Container>
            </ResponsiveContainer>
            <Footer />
        </>
    );
};

export default Login;
