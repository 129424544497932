// hooks/useImageUpload.ts
import { useState } from "react";
import { uploadThumbnailImageToFirebase, saveThumbnailImageUrlToFirestore } from "../services/firebase/companyService";
import { doc, updateDoc } from "firebase/firestore";
import { firestore, storage } from "../firebase";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";

export const useImageUpload = () => {
    const [uploading, setUploading] = useState(false);

    const handleImageSave = async (companyId: string, selectedFile: File | null) => {
        if (!selectedFile) return;

        setUploading(true);
        try {
            const thumbnailUrl = await uploadThumbnailImageToFirebase(companyId, selectedFile);
            await saveThumbnailImageUrlToFirestore(companyId, thumbnailUrl);
            return { success: true, thumbnailUrl };
        } catch (error) {
            console.error("Error uploading image: ", error);
            return { success: false, error };
        } finally {
            setUploading(false);
        }
    };

    return { handleImageSave, uploading };
};

export const useFirebaseImageUpload = () => {
    const uploadImageToFirebase = async (path: string, blob: Blob): Promise<string> => {
        const storageRef = ref(storage, path);
        const snapshot = await uploadBytes(storageRef, blob);
        return await getDownloadURL(snapshot.ref);
    };

    return { uploadImageToFirebase };
};

export const useFirestoreUpdateDocument = () => {
    const updateFirestoreDocument = async (collectionName: string, docId: string, data: Record<string, any>) => {
        const docRef = doc(firestore, collectionName, docId);
        await updateDoc(docRef, data);
    };

    return { updateFirestoreDocument };
};
