import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Container } from '@mui/material';

import { firestore, storage } from '../firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

import { useSnackbar } from '../Context/SnackbarContext';
import { useUser } from '../Context/UserContext';

import SaveLeftFloatButton from '../components/Molecules/SaveLeftFloatButton';
import CompanyTopNavigationContent from '../components/Organisms/CompanyTopNavigationContent';
import NavigationBar from '../components/Organisms/NavigationBar';
import Footer from '../components/Organisms/Footer';

import ResponsiveContainer from '../components/Templates/ResponsiveContainer';
import SectionEditForm from '../components/Templates/OursSectionEditForm';

// Define the types for the form data sections
interface ImageData {
    previewUrl: string | null;
    filePath: string | null;
    file: File | null;
}

interface SectionData {
    text: string;
    images: ImageData[];
}

interface FormData {
    [key: string]: SectionData;
}

const CompanyOurs: React.FC = () => {
    const initialFormData: FormData = useMemo(() => ({
        whatWeDo: {
            text: '',
            images: [{ filePath: null, previewUrl: null, file: null }, { filePath: null, previewUrl: null, file: null }],
        },
        whyWeDoIt: {
            text: '',
            images: [{ filePath: null, previewUrl: null, file: null }, { filePath: null, previewUrl: null, file: null }],
        },
        howWeDoIt: {
            text: '',
            images: [{ filePath: null, previewUrl: null, file: null }, { filePath: null, previewUrl: null, file: null }],
        },
    }), []);
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [companyInfo, setCompanyInfo] = useState<any>(null);

    const { id: companyId } = useParams<{ id: string }>();
    const { showSnackbar } = useSnackbar();
    const { user } = useUser();
    const hasEditPermission = user?.userType === 'company' && user?.companyId === companyId;

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            const docRef = doc(firestore, 'companies', companyId!);
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const id = docSnap.id;

                    const updatedFormData = Object.keys(initialFormData).reduce((acc, sectionKey) => {
                        const section = data[sectionKey] || {};
                        acc[sectionKey] = {
                            text: section.text || '',
                            images: section.images || initialFormData[sectionKey].images,
                        };
                        return acc;
                    }, {} as FormData);

                    setFormData(updatedFormData);
                    setCompanyInfo({ id, ...data });
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        if (companyId) {
            fetchCompanyInfo();
        }
    }, [companyId, initialFormData]);

    const handleTextChange = (sectionKey: string, newText: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [sectionKey]: {
                ...prevFormData[sectionKey],
                text: newText,
            },
        }));
    };

    const handleSubmit = async () => {
        try {
            await Promise.all(
                Object.keys(formData).map(async (sectionKey) => {
                    const sectionData = formData[sectionKey];
                    const textData = sectionData.text;

                    const imageUploadPromises = sectionData.images.map(async (image, index) => {
                        const currentImageInfo = companyInfo[sectionKey]?.images?.[index];

                        // currentImageInfo が存在するか確認し、filePathがある場合にのみ削除を行う
                        if (image.file && currentImageInfo?.filePath) {
                            try {
                                const oldImageRef = ref(storage, currentImageInfo.filePath);
                                await deleteObject(oldImageRef);
                            } catch (error) {
                                console.error(`Failed to delete old image at ${currentImageInfo.filePath}:`, error);
                            }
                        }

                        if (image.file) {
                            const storagePath = `company_ours/${companyId}/ours/${sectionKey}_${index + 1}_${uuidv4()}`;
                            const imageRef = ref(storage, storagePath);
                            await uploadBytes(imageRef, image.file);
                            const imageUrl = await getDownloadURL(imageRef);
                            return { previewUrl: imageUrl, filePath: storagePath, file: null };
                        }

                        return { previewUrl: image.previewUrl, filePath: currentImageInfo?.filePath || null, file: null };
                    });

                    const images = await Promise.all(imageUploadPromises);

                    const updateData = {
                        [`${sectionKey}.text`]: textData,
                        [`${sectionKey}.images`]: images,
                    };

                    await updateDoc(doc(firestore, 'companies', companyId!), updateData);
                })
            );
            showSnackbar({ message: '更新に成功しました', type: 'success' });
        } catch (error) {
            console.error('更新エラー:', error);
            showSnackbar({ message: '更新に失敗しました', type: 'error' });
        }
    };


    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <CompanyTopNavigationContent company={companyInfo} />
            </ResponsiveContainer>
            <Container maxWidth="md" sx={{ mt: { xs: 6, sm: 12 }, mb: { xs: 19, sm: 4 } }}>
                {['whatWeDo', 'whyWeDoIt', 'howWeDoIt'].map((sectionKey) => (
                    <SectionEditForm
                        key={sectionKey}
                        sectionKey={sectionKey}
                        formData={formData}
                        onTextChange={handleTextChange}
                        setFormData={setFormData}
                        companyId={companyId!}
                        hasEditPermission={hasEditPermission}
                    />
                ))}
                {hasEditPermission && <SaveLeftFloatButton handleSubmit={handleSubmit} disabled={false} />}
            </Container>
            <Footer />
        </>
    );
};

export default CompanyOurs;
