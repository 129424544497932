import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { addAuthId, deleteAuthId } from '../services/firebase/authIdService';

export const useAuthId = (companyId: string) => {
    const [newRole, setNewRole] = useState('');
    const newAuthId = `${companyId}${uuidv4()}`;

    const handleAddAuthId = async (onSuccess: () => void, onError: (error: any) => void) => {
        await addAuthId(
            companyId,
            newAuthId,
            newRole,
            () => {
                setNewRole('');
                onSuccess();
            },
            (error) => {
                onError(error);
            }
        );
    };

    const handleDeleteAuthId = async (authId: string, onSuccess: () => void, onError: (error: any) => void) => {
        await deleteAuthId(
            companyId,
            authId,
            () => {
                onSuccess();
            },
            (error) => {
                onError(error);
            }
        );
    };


    return {
        newRole,
        setNewRole,
        newAuthId,
        handleAddAuthId,
        handleDeleteAuthId,
    };
};
