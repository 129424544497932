// hooks.ts
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../Context/UserContext';
import {
    deleteCertificationOrAward,
    deleteHistory,
    fetchUserHistory,
    saveCertificationOrAward,
    saveHistoryData,
    updateHistoryData,
    updateUserInFirestore
} from '../services/firebase/userService';
import { getUserFromFirestore } from '../services/firebase/userService';
import { User } from '../types/User';
import { useSnackbar } from '../Context/SnackbarContext';

interface ModalState {
    open: boolean;
    data: any;
    type: 'view' | 'edit' | 'add' | 'history' | null;
    category?: string;
}

export const useProfile = () => {
    const { user: loginUser } = useUser();
    const { id: userId } = useParams<{ id: string }>();

    const [user, setUser] = useState<User | null>(null);
    const [timelineItems, setTimelineItems] = useState<any[]>([]);

    useEffect(() => {
        if (!userId) return;
        const fetchUser = async () => {
            const fetchedUser = await getUserFromFirestore(userId);
            if (fetchedUser) setUser(fetchedUser as User);
        };
        if (userId === loginUser?.uid) {
            setUser(loginUser);
        } else {
            fetchUser();
        }
    }, [userId, loginUser]);

    useEffect(() => {
        const getHistory = async () => {
            if (user?.documentId) {
                const historyItems = await fetchUserHistory(user.documentId);
                setTimelineItems(historyItems);
            }
        };
        getHistory();
    }, [user]);

    return {
        user,
        timelineItems,
        isUser: loginUser?.uid === userId,
    };
};

export const useProfileModals = (userDocId: string | undefined) => {
    const [modalState, setModalState] = useState<ModalState>({
        open: false,
        data: null,
        type: null,
    });

    const { showSnackbar } = useSnackbar();

    const handleSaveToFirestore = async (fieldName: string, fieldValue: string) => {
        if (!userDocId) return;
        try {

            await updateUserInFirestore(userDocId, { [fieldName]: fieldValue });
            showSnackbar({ type: 'success', message: '保存しました' });
        } catch (error) {
            showSnackbar({ type: 'error', message: '保存に失敗しました' });
        }
    };

    const handleOpenModal = (data = {}, type = 'view', category?: string) => {
        const initialData = {
            type: 'education',
            organization: '',
            position: '',
            employeeType: '',
            description: '',
            major: '',
            learned: '',
            startedAt: '',
            endedAt: '',
            isCurrent: false,
            isDisabled: false,
        };

        if (Object.keys(data).length === 0 && type === 'history') {
            data = initialData;
        }
        setModalState({ open: true, data, type: type as 'view' | 'edit' | 'add' | 'history' | null, category });
    };

    const handleCloseModal = () => {
        setModalState({ open: false, data: null, type: null });
    };

    const handleSaveHistoryData = async (data: any) => {
        if (!userDocId) return;
        try {
            await saveHistoryData(data, userDocId);
            handleCloseModal();
        } catch (error) {
            showSnackbar({ type: 'error', message: '保存に失敗しました' });
        }
    };

    const handleUpdateHistoryData = async (
        data: any,
        userDocId: string,
        historyDocId: string
    ) => {
        try {
            await updateHistoryData(data, userDocId, historyDocId);
            handleCloseModal();
        } catch (error) {
            showSnackbar({ type: 'error', message: '更新に失敗しました' });
        }
    };

    const handleDeleteHistory = async (
        userDocId: string,
        historyDocId: string
    ) => {
        try {
            await deleteHistory(userDocId, historyDocId);
            handleCloseModal();
        } catch (error) {
            showSnackbar({ type: 'error', message: '削除に失敗しました' });
        }
    };

    const handleSaveCertificationOrAward = async (data: any, category: string) => {
        if (!userDocId) return;
        try {
            await saveCertificationOrAward(data, userDocId, category);
            handleCloseModal();
        } catch (error) {
            showSnackbar({ type: 'error', message: '保存に失敗しました' });
        }
    };

    const handleDeleteCertificationOrAward = async (itemId: string, category: string) => {
        if (!userDocId) return;
        try {
            await deleteCertificationOrAward(itemId, userDocId, category);
            handleCloseModal();
        } catch (error) {
            showSnackbar({ type: 'error', message: '削除に失敗しました' });
        }
    };

    return {
        modalState,
        handleSaveToFirestore,
        handleOpenModal,
        handleCloseModal,
        handleSaveHistoryData,
        handleSaveCertificationOrAward,
        handleDeleteCertificationOrAward,
        handleUpdateHistoryData,
        handleDeleteHistory,
    };
};