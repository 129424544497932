import React from 'react';
import {
    ListItem,
    ListItemIcon,
    Avatar,
    ListItemText,
    Typography,
    // Chip, 
    Skeleton
} from '@mui/material';
// import { grey } from '@mui/material/colors';

interface CustomListItemProps {
    avatarSrc: string;
    title: string;
    // tag: string;
    lastMessage: string;
    isActive: boolean;
    onClick: () => void;
    isLoading?: boolean;
}

const CustomListItem: React.FC<CustomListItemProps> = ({
    avatarSrc,
    title,
    // tag,
    lastMessage,
    isActive,
    onClick,
    isLoading = false
}) => {
    // const getChipColor = (tag: string) => {
    //     switch (tag) {
    //         case 'AI担当者':
    //             return 'primary';
    //         case '企業担当者':
    //             return 'secondary';
    //         case 'スカウト':
    //             return 'warning';
    //         case 'その他':
    //             return 'default';
    //         default:
    //             return 'default';
    //     }
    // };

    // "その他"のタグの背景色を設定
    // const customTagStyle = tag === 'その他' ? { bgcolor: grey[500], color: '#fff' } : {};

    return (
        <ListItem
            onClick={onClick}
            button
            sx={{
                bgcolor: isActive ? 'grey.300' : 'background.paper',
                '&:hover': {
                    bgcolor: isActive ? 'grey.200' : 'grey.100',
                },
                transition: 'background-color 0.3s ease',
                borderRadius: '4px',
                marginY: '2px',
            }}
        >
            <ListItemIcon>
                {/* <Avatar alt="Profile Picture" src={avatarSrc} sx={{ width: 56, height: 56 }} /> */}
                {isLoading ? (
                    <Skeleton variant="circular" width={56} height={56} />
                ) : (
                    <Avatar alt="Profile Picture" src={avatarSrc} sx={{ width: 56, height: 56 }}>
                        {title.charAt(0)}
                    </Avatar>
                )}
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }}>
                {/* タイトル */}
                {isLoading ? (
                    <Skeleton width="60%" />
                ) : (
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                            fontWeight: 'bold',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {title}
                    </Typography>
                )}
                {/* タグ */}
                {isLoading ? (
                    <Skeleton width="40%" />
                ) : (
                    // <Chip label={tag} size="small" color={getChipColor(tag)} sx={customTagStyle} />
                    null
                )}
                {/* タグ */}
                {/* 最終メッセージ */}
                {isLoading ? (
                    <Skeleton width="80%" />
                ) : (
                    <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{
                            mt: 1,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {lastMessage}
                    </Typography>
                )}
            </ListItemText>
        </ListItem>
    );
};

export default CustomListItem;
