// React and hooks
import React, { useState, useRef } from 'react';

// Third-party components and utilities
import AvatarEditor from 'react-avatar-editor';

// Material-UI components
import { Dialog, DialogContent, Stack, Box, Slider, Button } from '@mui/material';

import { useFirebaseImageUpload, useFirestoreUpdateDocument } from '../../Hooks/useImageUpload';

interface ProfileImageUploadProps {
    imageModalOpen: boolean;
    setImageModalOpen: (open: boolean) => void;
    image: File | string;
    width: number;
    height: number;
    savePath: string;
    collectionName: string;
    docId: string;
    saveUrlFieldName: string;
    saveUrlStateFieldName: string;
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
    imageModalOpen,
    setImageModalOpen,
    image,
    width,
    height,
    savePath,
    collectionName,
    docId,
    saveUrlFieldName,
    saveUrlStateFieldName
}) => {
    const [scale, setScale] = useState<number>(1);
    const editorRef = useRef<AvatarEditor>(null);
    const { uploadImageToFirebase } = useFirebaseImageUpload();
    const { updateFirestoreDocument } = useFirestoreUpdateDocument();

    const handleScaleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setScale(newValue);
        }
    };

    const handleImageSave = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas();
            canvas.toBlob(blob => {
                if (blob) {
                    uploadImageToFirebase(savePath, blob).then((downloadURL) => {
                        updateFirestoreDocument(collectionName, docId, {
                            [saveUrlFieldName]: downloadURL,
                            [saveUrlStateFieldName]: true
                        }).then(() => {
                            setImageModalOpen(false);
                        }).catch((error) => {
                            console.error("Error updating document: ", error);
                        });
                    }).catch((error) => {
                        console.error("Error uploading file to Firebase Storage: ", error);
                    });
                }
            });
        }
    };

    return (
        <Dialog
            open={imageModalOpen}
            onClose={() => setImageModalOpen(false)}
        >
            <DialogContent>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
                    <Box sx={{ borderRadius: '8px', backgroundColor: 'white' }}>
                        <AvatarEditor
                            ref={editorRef}
                            image={image}
                            width={width}
                            height={height}
                            border={50}
                            borderRadius={height}
                            color={[205, 205, 205, 0.6]} // RGBA
                            scale={scale}
                            backgroundColor={'#ddd'}
                        />
                    </Box>
                    <Slider
                        min={0.1}
                        max={2}
                        step={0.01}
                        value={scale}
                        onChange={handleScaleChange}
                        aria-labelledby="continuous-slider"
                    />
                    <Button onClick={handleImageSave} variant='contained' fullWidth>保存</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ProfileImageUpload;