// src/components/PasswordResetDialog.js
import React from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

import { usePasswordResetMail } from '../../Hooks/usePasswordReset';

interface PasswordResetDialogProps {
    open: boolean;
    onClose: () => void;
}

const PasswordResetDialog: React.FC<PasswordResetDialogProps> = ({ open, onClose }) => {
    const { register, handleSubmit, handleResetPassword, reset, errors, isValid } = usePasswordResetMail(onClose);

    const handleClose = () => {
        reset(); // フォームのリセット、ここでemailフィールドもリセットされる
        onClose(); // ダイアログを閉じる
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>パスワードリセット</DialogTitle>
            <form onSubmit={handleSubmit(handleResetPassword)}>
                <DialogContent>
                    <DialogContentText>
                        パスワードリセットのためのリンクを送るメールアドレスを入力してください。
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="メールアドレス"
                        type="email"
                        fullWidth
                        variant="outlined"
                        {...register("email", {
                            required: "メールアドレスは必須です",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "有効なメールアドレスを入力してください"
                            }
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>キャンセル</Button>
                    <Button type="submit" disabled={!isValid} variant="contained">送信</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default PasswordResetDialog;
