// services/firebase/companyService.ts
import {
    doc,
    updateDoc,
    serverTimestamp,
    collection,
    addDoc,
} from 'firebase/firestore';
import { firestore } from '../../firebase';


export const deleteAuthId = async (
    companyId: string, 
    authId: string,
    onSuccess: () => void,
    onerror: (error: any) => void
) => {
    const authIdRef = doc(firestore, "companies", companyId, "authIds", authId);
    try {
        await updateDoc(authIdRef, {
            isDeleted: true,
            deletedAt: serverTimestamp()
        });
        onSuccess();
    } catch (error) {
        onerror(error);
    }
};

export const addAuthId = async (companyId: string, authId: string, newRole: string, onSuccess: () => void, onError: (error: any) => void) => {
    if (!companyId) return;

    const companyRef = doc(firestore, 'companies', companyId);
    const authIdsCollectionRef = collection(companyRef, 'authIds');
    try {
        await addDoc(authIdsCollectionRef, {
            authId,
            role: newRole,
            usedCount: 0,
            createdAt: serverTimestamp(),
            isDeleted: false
        });
        onSuccess();
    } catch (error) {
        onError(error);
    }
};