// getImageUrl.ts
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

/**
 * Firebase Storageから画像のダウンロードが可能なURLを取得する.
 * 複数の拡張子（.webp, .jpg, .png）を順に試す
 * 
 * @param {string} imagePath - Firebase Storageの画像のパス.
 * @returns {Promise<string>} - Firebase Storageの画像のURL.
 */
export const getImageUrl = async (imagePath: string): Promise<string> => {
    const extensions = ['.webp', '']; // 試す拡張子のリスト
    for (const ext of extensions) {
        try {
            const fileRef = ref(storage, `${imagePath}${ext}`);
            const url = await getDownloadURL(fileRef);
            return url; // URLが取得できたら返す
        } catch (error) {
            // URLが見つからない場合は次の拡張子を試す
            if ((error as any).code !== 'storage/object-not-found') {
                throw error; // 他のエラーがあればスローする
            }
        }
    }
    // すべての拡張子が見つからなかった場合、デフォルト画像のURLを返す
    return 'https://sus-shokunin.com/wordpress/wp-content/uploads/2016/08/noimage.jpg';
};
