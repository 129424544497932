import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { getMessagesQuery, getUserIcons } from '../services/firebase/messageService';

interface Message {
    id: string;
    senderId: string;
    text: string;
    timestamps: any;
    createdAt: any;
    updatedAt: any;
}

interface UserIcon {
    uid: string;
    userIconUrl: string;
}

export const useMessages = (activeChat: string) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [userIcons, setUserIcons] = useState<UserIcon[]>([]);

    useEffect(() => {
        if (!activeChat) return;

        // メッセージの取得
        const q = getMessagesQuery(activeChat);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            } as Message));
            setMessages(msgs);
        });

        // ユーザーアイコンの取得
        const fetchUserIcons = async () => {
            const icons = await getUserIcons(activeChat);
            setUserIcons(icons);
        };

        fetchUserIcons();

        return () => unsubscribe();
    }, [activeChat]);

    return { messages, userIcons };
};
