import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField
} from '@mui/material';
import { useAuthId } from '../../Hooks/useAuthId';

interface AuthIdDialogProps {
    companyId: string;
    open: boolean;
    onClose: () => void;
}

const AuthIdDialog: React.FC<AuthIdDialogProps> = ({ companyId, open, onClose }) => {
    const {
        newRole,
        setNewRole,
        newAuthId: authId,
        handleAddAuthId
    } = useAuthId(companyId);

    const handleAddClick = async () => {
        await handleAddAuthId(
            () => {
                onClose();
            },
            (error) => {
                console.error("Error adding document: ", error);
            }
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>認証IDの管理</DialogTitle>
            <DialogContent>
                <TextField
                    label="認証ID"
                    fullWidth
                    value={authId}
                    disabled
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="role-select-label">役割</InputLabel>
                    <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={newRole}
                        label="役割"
                        onChange={(e) => setNewRole(e.target.value)}
                    >
                        <MenuItem value={'admin'}>管理者</MenuItem>
                        <MenuItem value={'editor'}>編集ユーザー</MenuItem>
                        <MenuItem value={'chat_user'}>チャットユーザー</MenuItem>
                        <MenuItem value={'viewer'}>閲覧ユーザー</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={handleAddClick}>追加</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AuthIdDialog;
