import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

interface SaveOnBlurTextFieldProps {
    initialValue?: string;
    onBlur?: () => void;
    onFocus?: () => void;
    onSave: (value: string) => void;
    TypographyProps?: {
        variant?: 'h3' | 'body1' | 'body2';
        sx?: object;
    };
    multiline?: boolean;
    initialRows: number;
    maxRows: number;
    greyBackground?: boolean;
    label?: string;
    isDisabled?: boolean;
}

// Typographyのvariantに応じたスタイルをマッピングする関数
const getStylesForVariant = (variant?: string) => {
    switch (variant) {
        case 'h3':
            return {
                margin: 0,
                fontFamily: "Roboto, Helvetica, Arial",
                fontWeight: 700,
                fontSize: '3rem',
                lineHeight: 1.167,
                letterSpacing: '0em',
                color: "rgb(0, 0, 0)",
            };
        case 'body1':
            return {
                fontSize: '1rem',
                fontWeight: 400,
                color: "rgb(0, 0, 0)",
            };
        case 'body2':
            return {
                fontSize: '0.875rem',
                fontWeight: 400,
            };
        // 他のvariantについても必要に応じて追加
        default:
            return {};
    }
};

const SaveOnBlurTextField: React.FC<SaveOnBlurTextFieldProps> = ({
    initialValue = '',
    onBlur = () => { },
    onFocus = () => { },
    onSave,
    TypographyProps = {},
    multiline = true,
    initialRows,
    maxRows,
    greyBackground = false,
    label = '',
    isDisabled
}) => {
    const [value, setValue] = useState(initialValue);
    const [rows, setRows] = useState(initialRows);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    useEffect(() => {
        // コンポーネントのpropsであるinitialValueが変更された場合、
        // 内部状態も更新する
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        // テキストの長さに応じて行数を調整する関数
        const adjustRows = (text: string) => {
            if (multiline) {
                const lineHeight = inputRef.current ? parseInt(window.getComputedStyle(inputRef.current).lineHeight, 10) : 1;
                const newRows = Math.floor((text.split('\n').length * lineHeight) / lineHeight);
                if (newRows > initialRows) {
                    setRows(newRows <= maxRows ? newRows : maxRows); // テキストの行数がinitialRowsより多い場合、行数を更新
                }
            }
        };

        // コンポーネントのマウント時とvalueが変更された時に行数を調整
        adjustRows(value);
    }, [value, multiline, initialRows, maxRows]);

    const handleBlur = () => {
        if (value.trim() !== initialValue.trim()) { // 値が変更されていればonSaveを呼び出す
            onSave(value);
        }
        onBlur();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(event.target.value);
    };

    // Typographyのvariantに基づいたスタイルを取得
    const variantStyles = getStylesForVariant(TypographyProps.variant);

    return (
        <TextField
            inputRef={inputRef}
            variant="standard"
            fullWidth
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            value={value}
            onFocus={onFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            label={label}
            disabled={isDisabled}
            InputProps={{
                disableUnderline: true,
                sx: {
                    ...TypographyProps.sx,
                    ...variantStyles,
                    '&.Mui-disabled': {
                        color: value === '' ? 'grey.500' : 'inherit', // 空の時はグレーに、それ以外は継承色を使用
                        '-webkit-text-fill-color': value === '' ? 'grey.500' : 'inherit', // Chrome、Safari 用
                        '& input': {
                            '&:focus': { outline: 'none' },
                            padding: '0px',
                        },
                        '& textarea': {
                            padding: 2,
                            '&:focus': {
                                outline: 'none',
                                backgroundColor: greyBackground ? 'grey.100' : 'inherit', // greyBackgroundがtrueの場合、背景をグレーに設定
                            },
                        },
                    },
                    color: value === '' ? 'grey.500' : 'inherit',
                    '& input': {
                        '&:focus': { outline: 'none' },
                        padding: '0px',
                    },
                    '& textarea': {
                        padding: 2,
                        '&:focus': {
                            outline: 'none',
                            backgroundColor: greyBackground ? 'grey.100' : 'inherit', // greyBackgroundがtrueの場合、背景をグレーに設定
                        },
                        color: value === '' ? 'grey.500' : 'inherit', // 空のときはグレーに、それ以外は継承色を使用
                    },
                },
            }}
        />
    );
};

export default SaveOnBlurTextField;
