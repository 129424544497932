// 外部リンクやエンドポイントをまとめるファイル

export const EXTERNAL_LINKS = {
    home: 'https://www.cielinc.co.jp/',
    terms: 'https://www.cielinc.co.jp/terms',
    privacyPolicy: 'https://www.cielinc.co.jp/privacy-policy',
    law: 'https://www.cielinc.co.jp/law',
    company: 'https://www.cielinc.co.jp/company',
    news: 'https://www.cielinc.co.jp/news',
    contact: 'https://www.cielinc.co.jp/contact',
};