export const mailTemplate = ({activeChatRoomId}: {activeChatRoomId: string}) => `
<!DOCTYPE html>
    <html lang="ja">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>通知メール</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                background-color: #f8f9fa;
            }

            .container {
                max-width: 600px;
                margin: 0px auto;
                padding: 10px;
                border-radius: 8px;
                background-color: #fff;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            }

            .company-logo {
                text-align: center;
                margin-bottom: 0px;
            }

            .header h1 {
                text-align: center;
                font-size: 1.4rem;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .content {
                padding: 0 20px;
                text-align: center;
            }

            .content p {
                margin-bottom: 20px;
            }

            .content-info {
                text-align: left;
            }

            .center-content {
                display: flex;
                justify-content: center;
            }

            .btn {
                display: inline-block;
                margin: 20px 0;
                padding: 0px 20px;
                background-color: #007bff;
                color: #fff;
                text-decoration: none;
                border: none;
                border-radius: 8px;
                cursor: pointer;
            }

            .btn a {
                text-decoration: none;
                font-size: 1.2rem;
                color: #fff;
                text-decoration: none;
            }

            .footer {
                text-align: center;
                margin-top: 20px;
                padding-top: 20px;
                border-top: 1px solid #ccc;
                font-size: 12px;
            }
        </style>
    </head>

    <body>

        <!-- 企業ロゴを表示 -->
        <div class="company-logo">
            <img src="https://firebasestorage.googleapis.com/v0/b/ciel-dac23.appspot.com/o/meta%2Fciel_logo.001.png?alt=media&token=b8684362-735d-4b4f-9971-97270e253f0f"
                alt="企業ロゴ" width="200">
        </div>
        <div class="container">
            <div class="header">
                <h1>あなたにメッセージが届いています</h1>
            </div>
            <div class="content">
                <p class="content-info">あなたにCielを通してメッセージが届いています。いますぐCielにアクセスして確認しませんか？</p>
                <p class="content-info">通常、24時間以内に返信することで、相手から返信を受け取れる可能性が高くなります。</p>
                <div class="center-content btn">
                    <a href="https://app.cielinc.co.jp/chat/${activeChatRoomId}" class="btn">いますぐ返信する</a>
                </div>
                <p>ご不明点がございましたら、お気軽にお問い合わせください。</p>
            </div>
            <div class="footer">
                メールの配信停止は、Cielへログイン後に設定画面から行うことができます。<br>
                <p></p>
                このメールは自動送信されています。ご返信いただいてもお答えできませんのでご了承ください。<br>
                Copyright © 2024 Ciel, Inc. All rights reserved.
            </div>
        </div>

    </body>

</html>
`

export const gifteeMailTemplate = (gifteeBoxUrl: string) => `
<!DOCTYPE html>
<html lang="ja">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>通知メール</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f8f9fa;
        }

        .container {
            max-width: 600px;
            margin: 0px auto;
            padding: 10px;
            border-radius: 8px;
            background-color: #fff;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }

        .company-logo {
            text-align: center;
            margin-bottom: 0px;
        }

        .header h1 {
            text-align: center;
            font-size: 1.4rem;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .content {
            padding: 0 20px;
            text-align: center;
        }

        .content p {
            margin-bottom: 20px;
        }

        .content-info {
            text-align: left;
        }

        .center-content {
            display: flex;
            justify-content: center;
        }

        .btn {
            display: inline-block;
            margin: 20px 0;
            padding: 0px 20px;
            background-color: #007bff;
            color: #fff;
            text-decoration: none;
            border: none;
            border-radius: 8px;
            cursor: pointer;
        }

        .btn a {
            text-decoration: none;
            font-size: 1.2rem;
            color: #fff;
            text-decoration: none;
        }

        .footer {
            text-align: center;
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #ccc;
            font-size: 12px;
        }
    </style>
</head>

<body>

    <!-- 企業ロゴを表示 -->
    <div class="company-logo">
        <img src="https://firebasestorage.googleapis.com/v0/b/ciel-dac23.appspot.com/o/meta%2Fciel_logo.001.png?alt=media&token=b8684362-735d-4b4f-9971-97270e253f0f"
            alt="企業ロゴ" width="200">
    </div>
    <div class="container">
        <div class="header">
            <h1>cielからgifteeBoxをお届けします</h1>
        </div>
        <div class="content">
            <p class="content-info">cielに情報を登録してくれてありがとうございます。いますぐgiftee Boxを確認してお楽しみください。</p>
            <p class="content-info">マイページからより詳細な情報が追加できます。企業のAIと話したり、スカウトを待ってみましょう。</p>
            <div class="center-content btn">
                <a href="${gifteeBoxUrl}" class="btn">giftee Boxを見る</a>
            </div>
            <p>ご不明点がございましたら、お気軽にお問い合わせください。</p>
        </div>
        <div class="footer">
            このメールは自動送信されています。ご返信いただいてもお答えできませんのでご了承ください。<br>
            Copyright © 2024 Ciel, Inc. All rights reserved.
        </div>
    </div>

</body>

</html>
`


