import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Container,
    Divider,
    Skeleton,
    Typography,
    TextField,
    useTheme,
    useMediaQuery,
} from '@mui/material';

// Context and Hooks
import { useUser } from '../Context/UserContext';
import { useCompanyData } from '../Hooks/useCompanyData';
import useFetchImageUrl from '../Hooks/useFetchImageUrl';

// Components
import SaveLeftFloatButton from '../components/Molecules/SaveLeftFloatButton';
import CompanyTopNavigationContent from '../components/Organisms/CompanyTopNavigationContent';
import CompanyInfoList from '../components/Organisms/CompanyInfoList';
import ValueTable from '../components/Organisms/ValueTable';
import ImageUploadModal from '../components/Organisms/ImageUploadModal';
import NavigationBar from '../components/Organisms/NavigationBar';
import Footer from '../components/Organisms/Footer';
import ResponsiveContainer from '../components/Templates/ResponsiveContainer';

// Define initial form data type
type FormData = {
    catchphrase: string;
    companyDetail: string;
    values: Array<{ valueId: number; description: string }>;
    imagePath?: string;
};

const initialFormData: FormData = {
    catchphrase: 'キャッチフレーズ',
    companyDetail: '会社概要',
    values: [
        { valueId: 1, description: '' },
        { valueId: 2, description: '' },
        { valueId: 3, description: '' },
    ],
};

const CompanyHome: React.FC = () => {
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { id: companyId } = useParams();
    const { user } = useUser();

    const { companyInfo, isLoading, updateCompany } = useCompanyData(companyId!);
    const { imageUrl, loading } = useFetchImageUrl(companyId!, isMobile);

    // ユーザーが企業ユーザーかつ役割がadminである場合にtrue、それ以外の場合はfalseを設定
    const hasEditPermission = user?.userType === 'company' && user?.companyId === companyId;

    useEffect(() => {
        if (companyInfo) {
            setFormData({
                catchphrase: companyInfo.catchphrase || initialFormData.catchphrase,
                companyDetail: companyInfo.companyDetail || initialFormData.companyDetail,
                values: companyInfo.values || initialFormData.values,
            });
        }
    }, [companyInfo]);

    const handleSubmit = async () => {
        await updateCompany(formData);
        setIsFormChanged(false);
    };

    // 企業HOMEの画像カードクリック時の処理
    const handleCardClick = () => {
        if (hasEditPermission) {
            setImageModalOpen(true);
        }
    };

    const handleInputChange = (field: string, value: string) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
        setIsFormChanged(true);
    };

    const handleValueChange = (index: number, field: string, value: string) => {
        const updatedValues = formData.values.map((val, i) =>
            i === index ? { ...val, [field]: value } : val
        );
        setFormData((prev) => ({
            ...prev,
            values: updatedValues,
        }));
        setIsFormChanged(true);
    };

    const renderCompanyDetailWithIndent = (text: string) => {
        return text.split('\n').map((line, index) => (
            <Typography
                key={index}
                variant="body1"
                sx={{ textIndent: '1rem', whiteSpace: 'pre-line', mb: 1 }}
            >
                {line}
            </Typography>
        ));
    };

    return (
        <>
            <ImageUploadModal
                open={imageModalOpen}
                handleClose={() => setImageModalOpen(false)}
                companyId={companyId!}
            />

            <NavigationBar />

            <ResponsiveContainer>
                <CompanyTopNavigationContent isLoading={isLoading} company={companyInfo!} />

                <Box sx={{
                    maxWidth: '100%',
                    maxHeight: 400,
                    overflow: 'hidden',
                    marginX: { xs: 0, sm: 0 }, // パディングを設定
                    borderRadius: { xs: '8px', sm: '8px' }, // カードの角を丸める
                    cursor: hasEditPermission ? 'pointer' : 'default', // 編集権限がある場合のみポインターに変更
                }}
                    onClick={handleCardClick}
                >
                    {loading ? (
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={isMobile ? 200 : 400}
                            sx={{ borderRadius: '8px' }}
                            animation="wave"
                        />
                    ) : (
                        <Box
                            component="img"
                            src={imageUrl}
                            alt="サムネイル"
                            sx={{
                                width: '100%', // 幅を100%に設定
                                height: { xs: 200, sm: 400 }, // 高さを固定値に設定
                                borderRadius: '8px', // カードの角を丸める
                                objectFit: 'cover', // コンテナを覆うように画像を調整
                                objectPosition: 'center', // 画像の中心をコンテナの中心に合わせる
                                transition: 'transform .5s ease', // トランジション効果を追加
                                '&:hover': {
                                    transform: hasEditPermission ? 'scale(1.05)' : 'none', // 編集権限がある場合のみズームイン効果を適用
                                    boxShadow: '0 4px 20px rgba(0,0,0,0.5)', // オプションで影を強調
                                },
                            }}
                        />
                    )}
                </Box>
            </ResponsiveContainer>

            <Container maxWidth="md" sx={{ mt: { xs: 2, sm: 6 }, mb: { xs: 19, sm: 4 } }}>
                {isLoading ? (
                    // ローディング中のスケルトン表示
                    <>
                        <Skeleton variant="text" width="100%" height={200} sx={{ mb: -2, mt: -6 }} />
                        <Skeleton variant="rectangular" width="100%" height={300} />
                    </>
                ) : hasEditPermission ? (
                    // 編集権限がある場合、編集可能なフィールドを表示
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <TextField
                            label="キャッチフレーズ"
                            fullWidth
                            value={formData.catchphrase}
                            onChange={(e) => handleInputChange('catchphrase', e.target.value)}
                            variant="outlined"
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="会社概要"
                            fullWidth
                            multiline
                            rows={10}
                            value={formData.companyDetail}
                            onChange={(e) => handleInputChange('companyDetail', e.target.value)}
                            variant="outlined"
                        />
                    </Box>
                ) : (
                    // 編集権限がない場合、ただのテキストとして表示
                    <>
                        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', fontSize: { xs: '1.7rem', sm: '3rem' }, mb: { xs: 2, sm: 4 } }}>
                            {formData?.catchphrase || companyInfo?.catchphrase}
                        </Typography>
                        {renderCompanyDetailWithIndent(formData?.companyDetail || companyInfo?.companyDetail)}
                    </>
                )}

                <Box sx={{ mt: 12 }}>
                    <Typography variant="h6" color='text.secondary' sx={{ fontWeight: 'bold', mb: 1 }}>価値観</Typography>
                    <Divider />
                    <ValueTable
                        isLoading={isLoading}
                        hasEditPermission={hasEditPermission}
                        companyValues={formData.values.map(value => ({ valueId: value.valueId.toString(), description: value.description }))}
                        onValueChange={handleValueChange}
                    />
                </Box>

                <CompanyInfoList
                    hasEditPermission={hasEditPermission}
                    company={companyInfo}
                    isLoading={isLoading}
                />


                {hasEditPermission && (
                    <SaveLeftFloatButton handleSubmit={handleSubmit} disabled={!isFormChanged} />
                )}

            </Container >
            <Footer />
        </>
    );
}

export default CompanyHome;