import React, { useState } from 'react';
import {
    Box,
    Stack,
    Typography,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    Skeleton,
} from '@mui/material';
import { red } from '@mui/material/colors';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';

import { Link } from 'react-router-dom';

import EditCompanyModal from '../Organisms/EditCompanyModal';

import { Company } from '../../types';

// 型定義
interface CompanyInfoListProps {
    hasEditPermission: boolean;
    company: Company;
    isLoading: boolean;
}

// CompanyInfoList コンポーネント
const CompanyInfoList: React.FC<CompanyInfoListProps> = ({ hasEditPermission, company, isLoading }) => {

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ mt: 4 }}>
            {hasEditPermission ? (
                <>
                    <Button variant="outlined" onClick={handleOpen}>会社情報を編集</Button>
                    <EditCompanyModal
                        open={open}
                        handleClose={handleClose}
                    />
                </>
            ) : (
                <>
                    <Stack spacing={0} sx={{ mt: 6, mb: 2 }}>
                        <Typography variant="h6" color='text.secondary' sx={{ fontWeight: 'bold', mb: 1 }}>会社情報</Typography>
                        <Box display="flex" alignItems="center" sx={{ mb: 2, mt: 2 }}>
                            {isLoading ? (
                                <Skeleton variant="circular" width={50} height={50} />
                            ) : (
                                <Avatar sx={{ bgcolor: red[500], width: 50, height: 50 }} aria-label="recipe" src={company?.iconUrl}>
                                    {company?.name ? company.name.substring(0, 2).toUpperCase() : 'NA'}
                                </Avatar>
                            )}
                            <Typography
                                variant="body1"
                                sx={{
                                    ml: 2,
                                    fontWeight: 'bold',
                                    color: 'black',
                                    textDecoration: 'none',
                                }}
                            >
                                {isLoading ? <Skeleton width={120} /> : company?.name}
                            </Typography>
                        </Box>
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <List dense={true}>
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <BusinessIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={isLoading ? <Skeleton width={150} /> : `設立年月： ${company?.establishYear}年 ${company?.establishMonth}月 ${company?.establishDay}日`}
                                        primaryTypographyProps={{ fontSize: '0.875rem' }}
                                    />
                                </ListItem>
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <PeopleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={isLoading ? <Skeleton width={100} /> : `メンバー: ${company?.employeeCount}`}
                                        primaryTypographyProps={{ fontSize: '0.875rem' }}
                                    />
                                </ListItem>
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <LocationOnIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={isLoading ? <Skeleton width={200} /> : company?.address || ''}
                                        primaryTypographyProps={{ fontSize: '0.875rem' }}
                                    />
                                </ListItem>
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <LanguageIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            isLoading ? (
                                                <Skeleton width={150} />
                                            ) : (
                                                <Link
                                                    to={company?.websiteUrl || ''}
                                                    target="_blank"
                                                    rel="noopener"
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    {company?.websiteUrl}
                                                </Link>
                                            )
                                        }
                                        primaryTypographyProps={{ fontSize: '0.875rem' }}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Stack>
                </>
            )}
        </Box>
    );
};

export default CompanyInfoList;
