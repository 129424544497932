// React
import React, { useState, useEffect } from 'react';

// React Hook Form
import { useForm, Controller } from 'react-hook-form';

// MUI コンポーネント & アイコン
import { TextField, Button, Dialog, DialogContent, DialogTitle, DialogActions, FormControl, IconButton, Typography, Link } from '@mui/material';
import { Delete as DeleteIcon, Mode as ModeIcon } from '@mui/icons-material';

// 日付ピッカー & 日付処理
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ja from 'dayjs/locale/ja';

/**
 * HighlightCardDialog Component
 *
 * @param {object} props - Component props
 * @param {boolean} props.open - Modal open state
 * @param {function} props.handleClose - Function to handle closing the modal
 * @param {function} props.onSave - Function to handle saving the form data
 * @param {object} props.data - Data to populate the form (used in edit mode)
 * @param {string} props.category - Category to determine the form labels (e.g., 'certifications', 'awards')
 * @param {function} props.onDelete - Function to handle deleting the item
 * @param {boolean} props.isDisabled - Boolean to determine if the edit button should be disabled
 * @param {'add' | 'edit' | 'view'} props.modalType - Type of the modal to determine the mode
 *
 * @returns {JSX.Element} The rendered HighlightCardDialog component
 */
const HighlightCardDialog: React.FC<{
    open: boolean;
    handleClose: () => void;
    onSave: (formData: any) => void;
    data: any;
    category: string;
    onDelete: (id: string) => void;
    isDisabled: boolean;
    modalType: "add" | "edit" | "view" | "history";
}> = ({ open, handleClose, onSave, data, category, onDelete, isDisabled, modalType }) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(modalType === 'add');
    const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: '',
            url: '',
            description: '',
            date: dayjs(),
        }
    });

    useEffect(() => {
        if (open) {
            if (modalType === 'edit' || modalType === 'view') {
                reset(data);
            } else {
                reset({
                    title: '',
                    url: '',
                    description: '',
                    // @ts-ignore
                    date: null,
                });
            }
        }
    }, [open, data, reset, modalType]);

    useEffect(() => {
        if (modalType === 'add') {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [modalType]);

    const handleSave = (formData: any) => {
        const validatedDate = dayjs(formData.date).isValid() ? dayjs(formData.date).format('YYYY-MM-DD') : null;
        onSave({
            ...formData,
            date: validatedDate,
        });
        handleClose();
    };

    const handleDelete = () => {
        onDelete(data.id);
        handleClose();
    };

    // カテゴリに応じたフォームのラベルを取得する関数
    const getFormLabels = (category: string) => {
        switch (category) {
            case 'certifications':
                return {
                    titleLabel: "資格名",
                    descriptionLabel: "資格の紹介や取得までのエピソード",
                    dateLabel: "取得日"
                };
            case 'awards':
                return {
                    titleLabel: "受賞名",
                    descriptionLabel: "受賞の詳細やエピソード",
                    dateLabel: "受賞日"
                };
            default:
                return {
                    titleLabel: "タイトル",
                    descriptionLabel: "説明",
                    dateLabel: "日付"
                };
        }
    };

    const { titleLabel, descriptionLabel, dateLabel } = getFormLabels(category);

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="modal-title" fullWidth maxWidth="sm">
            <DialogTitle id="modal-title">{isEditMode ? (modalType === 'add' ? '新規追加' : '編集') : data?.title || '詳細'}</DialogTitle>
            <form onSubmit={handleSubmit(handleSave)}>
                <DialogContent dividers>
                    {isEditMode ? (
                        // 編集モードのフォーム
                        <>
                            <TextField
                                margin="normal"
                                fullWidth
                                label={titleLabel}
                                {...register("title", { required: true })}
                                error={!!errors.title}
                                helperText={errors.title ? "This field is required" : ""}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                label="URL"
                                {...register("url")}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                label={descriptionLabel}
                                {...register("description")}
                                multiline
                                rows={4}
                            />

                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                // @ts-ignore
                                adapterLocale={ja}
                                dateFormats={{
                                    monthAndYear: 'YYYY年MM月',
                                    normalDate: 'YYYY年MM月DD日',
                                    keyboardDate: 'YYYY年MM月DD日',
                                }}
                                localeText={{
                                    previousMonth: '前へ',
                                    nextMonth: '次へ',
                                    // @ts-ignore
                                    cancelText: "キャンセル",
                                    okText: "選択",
                                }}
                            >
                                <FormControl component="fieldset" margin="normal" fullWidth>
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                views={['year', 'month', 'day']}
                                                openTo="year"
                                                format="YYYY年MM月DD日"
                                                label={dateLabel}
                                                value={field.value ? dayjs(field.value) : null}
                                                onChange={(newValue) => {
                                                    field.onChange(newValue && dayjs(newValue).isValid() ? newValue : null);
                                                }}
                                                // @ts-ignore
                                                renderInput={(params) => <TextField {...params} />}
                                                DialogProps={{
                                                    sx: {
                                                        '& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
                                                            fontSize: '1.5rem',
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                        </>
                    ) : (
                        // 詳細モードの表示
                        <>
                            {data?.url && (
                                <Typography variant="body1" gutterBottom>
                                    URL: <Link href={data?.url} target="_blank" rel="noopener noreferrer">{data.url}</Link>
                                </Typography>
                            )}
                            {data?.description && (
                                <Typography variant="body2" gutterBottom>
                                    説明: {data?.description}
                                </Typography>
                            )}
                            {data?.date && (
                                <Typography variant="body2" gutterBottom>
                                    期間: {data?.date}
                                </Typography>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {isEditMode ? (
                        // 編集モードのアクションボタン
                        <>
                            {data?.id && modalType !== 'add' && (
                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDelete}
                                    color="error"
                                >
                                    削除
                                </Button>
                            )}
                            <Button onClick={() => {
                                    handleClose();
                            }} color="primary" variant='outlined'>
                                キャンセル
                            </Button>
                            <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                                保存
                            </Button>
                        </>
                    ) : (
                        // 詳細モードのアクションボタン
                        <>
                            {isDisabled && (
                                <IconButton onClick={() => setIsEditMode(true)} color="primary" aria-label="edit">
                                    <ModeIcon />
                                </IconButton>
                            )}
                            <Button onClick={handleClose} color="primary" variant='outlined'>
                                閉じる
                            </Button>
                        </>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default HighlightCardDialog;
