// helpers/imageValidator.ts
export const validateImageFile = (file: File): { isValid: boolean, error?: string } => {
    const maxSize = 5 * 1024 * 1024; // 5MB
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    
    if (file.size > maxSize) {
        return { isValid: false, error: "ファイルサイズは5MB以下にしてください。" };
    }
    
    if (!allowedTypes.includes(file.type)) {
        return { isValid: false, error: "JPEG、PNG、GIF形式のファイルのみアップロードできます。" };
    }

    return { isValid: true };
};

export const validateImageDimensions = (width: number, height: number): { isValid: boolean, error?: string } => {
    if (width > 1000 || height > 1000) {
        return { isValid: false, error: "画像の幅と高さは1000px以下にしてください。" };
    }
    return { isValid: true };
};
