import { ElasticFetchCompany, CompanySearchFeature, CompanySearchParams, User } from '../types';
import { API_ENDPOINTS } from '../config/apiConfig';

// 各フィルタオブジェクトの型を定義
interface IsDeletedFilter {
    isdeleted: string;
}

interface NumChunksFilter {
    numchunks: { from: number }; // 例: { "from": 100 }
}

interface AllowsDirectChatFilter {
    allowsdirectchat: string; // 例: "true"
}

interface IdFilter {
    id: string[] | string; // 例: [companyBookMarks] または "none"
}

// フィルタの型定義（ユニオン型で定義）
type Filter = IsDeletedFilter | NumChunksFilter | AllowsDirectChatFilter | IdFilter;

// 返り値の型定義
interface FetchCompaniesResult {
    companies: ElasticFetchCompany[]; // 取得した会社データ
    totalCompanies: number;           // 会社の総数
    totalPages: number;               // 総ページ数
}

/**
 * 会社情報を取得するElasticSearchから取得してくるカスタムフック
 *
 * 指定されたフィルタとユーザー情報を元に、会社情報をElasticSearchAPIを叩くことで取得します。
 * ElasticSearchAPIは，Cloud Functionにホスティングされています．[タスク]
 *
 * @param {CompanySearchFeature[]} features - 検索に使用するフィルタ（検索文字，仕事のタイプ，カテゴリーの配列）
 * @param {User} user - 現在のユーザー情報
 * @param {CompanySearchParams} searchParams - 検索パラメータ（企業の特徴を選択する）
 * @param {number} itemsPerPage - 1ページあたりのアイテム数
 * @param {number} page - 現在のページ番号
 * @param {boolean} shouldFetch - フェッチを行うかどうかのフラグ
 * @returns {Promise<FetchCompaniesResult>} 取得された会社情報とページ情報
 * @throws {Error} APIリクエストが失敗した場合に発生
 *
 * @example
 * const { companies, totalCompanies, totalPages } = useFetchCompanies(features, user, searchParams, 10, 1);
 *
 * @note 会社情報の取得には数秒かかることがあります。
 */
export const useFetchCompanies = async (
    features: CompanySearchFeature[],
    user: User,
    searchParams: CompanySearchParams,
    itemsPerPage: number,
    page: number,
): Promise<FetchCompaniesResult> => {
    const endpoint = API_ENDPOINTS.elasticSearch;
    let filters: Filter[] = [{ isdeleted: "false" }];

    if (features.find(f => f.name === "AIの解答が正確" && f.enabled)) {
        filters.push({ "numchunks": { "from": 100 } });
    }
    if (features.find(f => f.name === "担当者と話せる" && f.enabled)) {
        filters.push({ "allowsdirectchat": "true" });
    }
    if (user && features.find(f => f.name === "ブックマーク済み" && f.enabled)) {
        if (user.companyBookMarks && user.companyBookMarks.length > 0) {
            filters.push({ "id": user.companyBookMarks });
        } else {
            filters.push({ "id": "none" });
        }
    }

    const requestData = {
        "query": searchParams.searchText,
        "filters": { "all": filters },
        "sort": [{ "viewcount": "desc" }],
        "page": { "size": itemsPerPage, "current": page }
    };

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return {
            companies: data.results as ElasticFetchCompany[],
            totalCompanies: data.meta.page.total_results,
            totalPages: data.meta.page.total_pages
        };
    } catch (error) {
        console.error("There was an error fetching the companies:", error);
        throw error;
    }
};

