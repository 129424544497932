import React, { useState, ChangeEvent, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
    Avatar,
    Box,
    Button,
    Link,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    Skeleton
} from '@mui/material';

import { useSnackbar } from '../../Context/SnackbarContext';
import { useUser } from '../../Context/UserContext';

import ProfileImageUpload from '../Input/ProfileImageUpload';
import ScrollFooterBarAIAndPerson from '../Molecules/ScrollFooterBarAIAndPerson';

import BookmarkButton from '../Molecules/BookmarkButton';
import RegistrationDialog from '../Organisms/RegistrationDialog';

import { startOrJoinChat } from '../../services/firebase/chatService';

// Define the company type
interface Company {
    id: string;
    name: string;
    websiteUrl?: string;
    iconUrl?: string;
    logoUrl?: string;
    allowsDirectChat?: boolean;
}

// Define the props type
interface CompanyTopNavigationContentProps {
    isLoading?: boolean;
    company: Company;
}

const activeLinkStyle = {
    color: '#0288d1',
    textDecoration: 'none',
    fontWeight: 'bold',
};

const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 'bold',
};

const CompanyTopNavigationContent: React.FC<CompanyTopNavigationContentProps> = ({ company, isLoading = false }) => {
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [image, setImage] = useState<string | null>(null);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { user } = useUser();

    const hasEditPermission = (user?.userType === 'company' || user?.userType === 'admin') && user?.companyId === company?.id;
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const savePath = `company_avatar/${company?.id}/avatar.png`;
    const getLinkStyle = (path: string) => (location.pathname === path ? activeLinkStyle : linkStyle);

    useEffect(() => {
        if (!isLoading) {
            setIsBookmarked(user?.companyBookMarks?.includes(company?.id) ?? false);
        }
    }, [user, company, isLoading]);

    const toggleBookmark = () => { setIsBookmarked(!isBookmarked) };

    const startOrJoinChatHandler = async ({ chatTag }: { chatTag: string }) => {
        if (!user) {
            setShowRegistrationDialog(true);
            return;
        }

        try {
            const chatId = await startOrJoinChat(user, company.id, chatTag, company as Company); // サービス関数を使用
            navigate(`/chat/${chatId}`);
        } catch (error) {
            showSnackbar({
                message: 'チャットの作成に失敗しました。',
                type: 'error',
            });
        }
    };

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result as string);
            };
            reader.readAsDataURL(e.target.files[0]);
            setImageModalOpen(true);
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: { xs: 2, md: 0 }, mb: 2, flexDirection: 'column' }}>
            <ProfileImageUpload
                imageModalOpen={imageModalOpen}
                setImageModalOpen={setImageModalOpen}
                image={image}
                width={250}
                height={250}
                savePath={savePath}
                collectionName="companies"
                docId={company?.id}
                saveUrlFieldName="iconUrl"
                saveUrlStateFieldName="hasIcon"
            />

            <RegistrationDialog open={showRegistrationDialog} onClose={() => setShowRegistrationDialog(false)} />

            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                {hasEditPermission && (
                    <input accept="image/*" type="file" id="icon-button-file" style={{ display: 'none' }} onChange={handleImageChange} />
                )}
                <label htmlFor="icon-button-file">
                    {isLoading ? (
                        <Skeleton variant="circular" width={56} height={56} />
                    ) : (
                        <Avatar
                            sx={{
                                mr: 3,
                                width: 56,
                                height: 56,
                                ...(hasEditPermission && {
                                    transition: 'transform .5s ease',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }),
                            }}
                            aria-label="upload picture"
                            component="span"
                            src={company?.iconUrl}
                        >
                            {company?.iconUrl ? <img src={company?.logoUrl} alt="company logo" /> : company?.name?.charAt(0)}
                        </Avatar>
                    )}
                </label>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: { xs: 'flex-end', md: '' } }}>
                    {isLoading ? (
                        <>
                            <Skeleton variant="text" width="150px" />
                            <Skeleton variant="text" width="100px" />
                        </>
                    ) : (
                        <>
                            <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>{company?.name}</Typography>
                            <Link href={company?.websiteUrl} target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>
                                <Typography variant="caption" color="text.secondary">{company?.websiteUrl}</Typography>
                            </Link>
                        </>
                    )}
                </Box>
            </Box>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                {hasEditPermission && (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/dashboard`} style={getLinkStyle(`/company/${company?.id}/dashboard`)}>DASHBOARD</RouterLink>
                    </Typography>
                )}
                {/* {hasEditPermission && (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/search`} style={getLinkStyle(`/company/${company?.id}/search`)}>ユーザー検索</RouterLink>
                    </Typography>
                )} */}
                {/* {hasEditPermission && (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/learning`} style={getLinkStyle(`/company/${company?.id}/learning`)}>学習</RouterLink>
                    </Typography>
                )} */}
                {isLoading ? (
                    <Skeleton variant="rectangular" width={120} height={40} />
                ) : (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/home`} style={getLinkStyle(`/company/${company?.id}/home`)}>HOME</RouterLink>
                    </Typography>
                )}
                {isLoading ? (
                    <Skeleton variant="rectangular" width={120} height={40} />
                ) : (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/ours`} style={getLinkStyle(`/company/${company?.id}/ours`)}>OURS</RouterLink>
                    </Typography>
                )}
                {user?.userType !== 'company' && !hasEditPermission && (
                    <BookmarkButton
                        itemId={company?.id}
                        isBookmarked={isBookmarked}
                        onBookmarkToggle={toggleBookmark}
                    />
                )}
                {user?.userType !== 'company' && !hasEditPermission && (
                    <>
                        {isSmUp ? (
                            <>
                                {/* <Button variant="contained" onClick={() => startOrJoinChatHandler({ chatTag: 'AI担当者' })}>AIと話す</Button> */}
                                {company?.allowsDirectChat && <Button variant="contained" onClick={() => startOrJoinChatHandler({ chatTag: '企業担当者' })}>担当者と話す</Button>}
                            </>
                        ) : (
                            <ScrollFooterBarAIAndPerson
                                avatarSrc={company?.logoUrl}
                                company={company}
                                // message={company?.allowsDirectChat ? 'AIや担当者と話すことができます' : 'この企業は直接チャットを許可していません。'}
                                message={company?.allowsDirectChat ? '担当者と話す' : 'この企業はチャットを許可していません。'}
                                startOrJoinChat={startOrJoinChatHandler}
                            />
                        )}
                    </>
                )}
                {hasEditPermission && (
                    <Button variant="contained" onClick={() => { navigate(`/chat/null`); }}>Chatへ</Button>
                )}
            </Stack>
        </Box>
    );
};

export default CompanyTopNavigationContent;
