// hooks/useCompanyForm.ts
import { useState, useEffect } from 'react';
import { Company } from '../types';
import { useCompany } from '../Context/CompanyContext';
import { updateCompanyInfo } from '../services/firebase/companyService';
import { calculateDaysInMonth } from '../utils/dateHelper';

// 企業ユーザーのみ使える関数
export const useCompanyForm = () => {
    const { company } = useCompany();
    const [info, setInfo] = useState<Company | undefined>();
    const [daysInMonth, setDaysInMonth] = useState<number[]>([]);

    useEffect(() => {
        setInfo(company);
    }, [company]);

    useEffect(() => {
        const year = info?.establishYear;
        const month = info?.establishMonth;
        if (year && month) {
            const days = calculateDaysInMonth(year, month);
            setDaysInMonth(days);

            if (info?.establishDay && info.establishDay > days.length) {
                setInfo((prev) => prev ? { ...prev, establishDay: null } : undefined);
            }
        }
    }, [info?.establishYear, info?.establishMonth, info?.establishDay]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
        const { name, value } = e.target;
        setInfo((prev) => {
            if (!prev) return undefined;
    
            const newValue =
                (name === 'establishYear' || name === 'establishMonth' || name === 'establishDay' || name === 'employeeCount')
                    ? Number(value)
                    : value;
    
            return { ...prev, [name as string]: newValue };
        });
    };

    const handleSave = async (info: Company | undefined): Promise<boolean> => {
        if (!info || !company?.id) return false;
        try {
            await updateCompanyInfo(company.id, info);
            return true;
        } catch (error) {
            console.error("Error updating company:", error);
            return false;
        }
    };

    return {
        info,
        daysInMonth,
        handleChange,
        handleSave,
    };
};
