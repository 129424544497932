// hooks/useCompanySignup.ts
import { useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import {
    authenticateCompanyID,
    incrementAuthIdUsage
} from '../services/firebase/companyService';
import { createUserInFirestore } from '../services/firebase/userService';
import { signUpWithEmail } from '../services/firebase/authService';

import { useSnackbar } from '../Context/SnackbarContext';
import { calculateAge } from '../utils/helpers/calculateAge';

export const useCompanySignup = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [companyData, setCompanyData] = useState<any>(null);
    const [companyRef, setCompanyRef] = useState<any>(null);
    const [authIdDocRef, setAuthIdDocRef] = useState<any>(null);
    const [role, setRole] = useState<string>('');
    const { showSnackbar } = useSnackbar();

    const authenticateCompany = async (authId: string) => {
        try {
            const { role, companyRef, companyData, authIdDocRef } = await authenticateCompanyID(authId);
            setRole(role);
            setCompanyRef(companyRef);
            setCompanyData(companyData);
            setAuthIdDocRef(authIdDocRef);
            setIsAuthenticated(true);
            showSnackbar({ message: `${role}ユーザーとして登録を行います`, type: 'success', title: '認証成功' });
        } catch (error) {
            showSnackbar({ message: (error as any).message, type: 'error', title: '認証失敗' });
        }
    };

    const submitCompanySignup = async (
        data: any,
        onSuccess: (companyId: string) => void,
        onFail: (error: any) => void,
    ) => {
        try {
            const birthDateTimestamp = Timestamp.fromDate(new Date(data.birthDate.toDate()));
            const age = calculateAge(new Date(data.birthDate.toDate()));

            // Firebaseにユーザーを登録
            const userCredential = await signUpWithEmail(data.email, data.password);
            const user = userCredential.user;

            const UserData = {
                email: data.email,
                phoneNumber: data.phoneNumber || '',
                lastName: data.lastName,
                firstName: data.firstName,
                birthDate: birthDateTimestamp,
                gender: data.gender,
                age: age,
                emailNotification: 'notify',
                hasCatchphrase: false,
                hasProfile: false,
                hasGoals: false,
                hasAwards: false,
                hasCertifications: false,
                hasUsericonurl: false,
                userType: "company",
                role: role,
                companyId: companyRef.id,
            }

            await createUserInFirestore(user.uid, UserData);
            // 認証IDの使用回数をインクリメント
            await incrementAuthIdUsage(authIdDocRef);

            onSuccess(companyRef.id);
        } catch (error: any) {
            onFail(error);
        }
    };

    return { isAuthenticated, companyData, authenticateCompany, submitCompanySignup };
};
