import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Joyride, { CallBackProps } from 'react-joyride';
import {
    Box,
    CssBaseline,
    IconButton,
    List,
    useMediaQuery,
    Typography,
    Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ArchiveIcon from '@mui/icons-material/Archive';
import RepartitionIcon from '@mui/icons-material/Repartition';


import { PcChatComponent } from '../components/Templates/PcChatView';
import { SmChatComponent, SmChatAppBar } from '../components/Templates/SmChatView';
import NavigationBar from '../components/Organisms/NavigationBar';
import CustomListItem from '../components/Molecules/CustomListItem';
import FinishDialog from '../components/Molecules/FinishDialog';

import { useUser } from '../Context/UserContext';
import { TourContext, handleCallback } from '../Context/TourContext';

import { useChat } from '../Hooks/useChat';
import { useArchivedChats } from '../Hooks/useChatRooms';
import { useMessages } from '../Hooks/useMessages';

const Chat: React.FC = () => {
    // 状態管理とリファレンス
    const [activeChat, setActiveChat] = useState<string | null>(null);
    const [openFinishTourDialog, setOpenFinishTourDialog] = useState<boolean>(false);
    const [isArchived, setIsArchived] = useState<boolean>(false);

    const scrollRef = useRef<HTMLDivElement>(null);
    const { id: chatId } = useParams<{ id: string }>();

    const { steps, setSteps, run, setRun } = useContext(TourContext);
    const { user } = useUser();

    const navigate = useNavigate();

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const { chatRooms, showArchivedChats, isLoading: isRoomListLoading } = useArchivedChats(user, isArchived);
    const activeChatRoom = chatRooms.find(room => room.id === activeChat);

    const { sendMessage, markChatAsDeleted, isLoadingAiResponse } = useChat(activeChatRoom, activeChat, user);
    const { messages, userIcons } = useMessages(chatId ?? '');

    useEffect(() => {
        setActiveChat(chatId || null);
    }, [chatId]);

    // メッセージが追加されたらスクロールする
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);


    // ここはチュートリアルのステップを設定している
    useEffect(() => {
        if (user) {
            setRun(user.tutorialStatus === 'completed' || user.tutorialStatus === 'skipped' ? false : true);
        } else {
            setRun(true);
        }
    }, [setRun, user]);

    useEffect(() => {
        if (steps.length === 0) {
            setRun(false);
            return;
        }

        const newSteps = [...steps];
        const lastStepIndex = newSteps.length - 1;

        const newData = {
            messageText: "どんな会社ですか？",
            user,
            activeChat,
            activeChatRoom,
            setRun,
            setOpenFinishTourDialog,
        };

        if (newSteps[lastStepIndex] && JSON.stringify(newSteps[lastStepIndex].data) !== JSON.stringify(newData)) {
            newSteps[lastStepIndex].data = newData;
            setSteps(newSteps);
        }
    }, [setSteps, activeChatRoom, user, activeChat, steps, setOpenFinishTourDialog, setRun]);

    const toggleChats = () => {
        setIsArchived((prev) => !prev);
    };

    return (
        <>
            {user && (
                <Joyride
                    callback={handleCallback as (data: CallBackProps) => void}
                    continuous={true}
                    run={run}
                    steps={steps}
                    stepIndex={2}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={true}
                    locale={{
                        back: '戻る',
                        close: '閉じる',
                        last: '質問する',
                        next: '次へ',
                        skip: 'スキップ',
                    }}
                    styles={{
                        options: {
                            zIndex: 1000,
                        },
                        buttonNext: {
                            backgroundColor: '#1976d2',
                            color: 'white',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                            border: 'none',
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                        },
                        buttonBack: {
                            backgroundColor: '#eeeeee',
                            color: '#616161',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                        },
                    }}
                />
            )}

            <FinishDialog open={openFinishTourDialog} onClose={() => setOpenFinishTourDialog(false)} />

            {isSm ? (
                <PcChatComponent
                    showArchivedChats={showArchivedChats}
                    toggleChats={toggleChats}
                    chatRooms={chatRooms}
                    activeChat={activeChat}
                    setActiveChat={setActiveChat}
                    activeChatRoom={activeChatRoom!}
                    user={user}
                    markChatAsDeleted={markChatAsDeleted}
                    messages={messages}
                    isLoadingAiResponse={isLoadingAiResponse}
                    scrollRef={scrollRef}
                    sendMessage={sendMessage}
                    userIcons={userIcons}
                    isRoomListLoading={isRoomListLoading}
                />
            ) : chatId === 'null' ? (
                <>
                    <NavigationBar />
                    <Box sx={{ mt: 10, mb: 0 }}>
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                    チャットルーム
                                </Typography>
                                {showArchivedChats ? (
                                    <IconButton aria-label="archive" onClick={toggleChats} sx={{ position: 'absolute', right: 0 }}>
                                        <RepartitionIcon fontSize="large" />
                                    </IconButton>
                                ) : (
                                    <IconButton aria-label="archive" onClick={toggleChats} sx={{ position: 'absolute', right: 0 }}>
                                        <ArchiveIcon fontSize="large" />
                                    </IconButton>
                                )}
                            </Box>
                            <List>
                                {chatRooms.map((room) => (
                                    < CustomListItem
                                        key={room.id}
                                        avatarSrc={room?.avatarSrc}
                                        title={room.title}
                                        // tag={room?.chatTag}
                                        lastMessage={room.lastMessage || 'メッセージを開始してください'}
                                        isActive={activeChat === room.id}
                                        onClick={() => {
                                            setActiveChat(room.id);
                                            navigate(`/chat/${room.id}`);
                                        }}
                                    />
                                ))}
                            </List>
                        </Box>
                    </Box>
                </>
            ) : (
                <>
                    <CssBaseline />
                    <SmChatAppBar activeChatRoom={activeChatRoom!} markChatAsDeleted={markChatAsDeleted} />

                    <Toolbar id="back-to-top-anchor" />
                    <SmChatComponent
                        messages={messages}
                        user={user!}
                        isLoadingAiResponse={isLoadingAiResponse}
                        scrollRef={scrollRef}
                        showArchivedChats={showArchivedChats}
                        sendMessage={sendMessage}
                        markChatAsDeleted={markChatAsDeleted}
                        userIcons={userIcons}
                    />
                </>
            )}
        </>
    );
};

export default Chat;
