import { useState } from "react";
import {
    addMessageToFirestore,
    callAiMessageEndpoint,
    markChatAsDeletedInFirestore,
    sendMailNotification
} from "../services/firebase/chatService";
import { firestore } from "../firebase"; // Assuming you have a Firebase config file
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../Context/SnackbarContext";
import { addLastMessageToChat } from "../services/firebase/chatService";

export const useChat = (activeChatRoom: any, activeChat: string | null, user: any) => {
    const [isLoadingAiResponse, setIsLoadingAiResponse] = useState(false);
    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar()

    const sendMessage = async (messageText: string) => {
        if (!activeChatRoom) return; // Ensure activeChatRoom is defined

        await addMessageToFirestore(activeChat!, messageText, user!.uid);
        await addLastMessageToChat(activeChat!, messageText, user!.uid);

        if (activeChatRoom.chatTag === 'AI担当者') {
            setIsLoadingAiResponse(true);
            try {
                const aiResponse = await callAiMessageEndpoint(
                    messageText,
                    activeChatRoom.companyId!,
                    activeChatRoom.title
                );
                if (aiResponse?.response?.choices?.length > 0) {
                    const aiMessageContent = aiResponse.response.choices[0].message.content;
                    await addMessageToFirestore(activeChat!, aiMessageContent, 'AI');
                }
            } catch (error) {
                console.error('AI message retrieval error:', error);
            } finally {
                setIsLoadingAiResponse(false);
            }
        } else {
            try {
                // Use participants and extract userId
                const otherParticipantIds = activeChatRoom.participants
                    .filter((participant: any) => participant.userId !== user?.uid)
                    .map((participant: any) => participant.userId);

                const notificationUids: string[] = [];
                for (const participantId of otherParticipantIds) {
                    const userRef = doc(firestore, 'users', participantId);
                    const userSnap = await getDoc(userRef);

                    if (userSnap.exists() && userSnap.data().emailNotification === 'notify') {
                        notificationUids.push(participantId);
                    }
                }

                if (notificationUids.length > 0) {
                    await sendMailNotification(notificationUids, activeChatRoom.id);
                }
            } catch (error) {
                console.error('Error adding mail to Firestore:', error);
            }
        }
    };

    const markChatAsDeleted = async () => {
        if (!activeChat) return;

        try {
            await markChatAsDeletedInFirestore(activeChat);
            navigate('/chat/null');
        } catch (error) {
            showSnackbar({ message: '再度お試しください', type: 'error', title: '削除失敗' });
        }
    };

    return {
        sendMessage,
        markChatAsDeleted,
        isLoadingAiResponse,
    };
};
