// React
import React, { useEffect } from 'react';

// React Hook Form
import { useForm, Controller } from 'react-hook-form';

// Material UI コンポーネント
import { Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Box, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

// 日付関連
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ja from 'dayjs/locale/ja';

// ユーティリティ
import { EMPLOYEE_TYPE_OPTIONS } from '../../utils/options';

type HistoryEditDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (data: any) => void;
    initialData?: any;
    isEdit: boolean;
    onDelete?: () => void;
};

type FormData = {
    type: string;
    organization: string;
    position?: string;
    employeeType?: string;
    description?: string;
    major?: string;
    learned?: string;
    startedAt: string;
    endedAt?: string;
    isCurrent: boolean;
    isDisabled: boolean;
};

const HistoryEditDialog: React.FC<HistoryEditDialogProps> = ({ open, onClose, onSave, initialData, isEdit, onDelete }) => {
    const { control, handleSubmit, watch, setValue, formState: { errors }, reset } = useForm < FormData > ();

    useEffect(() => {
        if (initialData) {
            reset({
                ...initialData,
                isCurrent: initialData.endedAt === "現在", // "現在"の場合はisCurrentをtrueにする
                isDisabled: false,
            });
        }
    }, [initialData, reset]);

    const isCurrent = watch("isCurrent");
    const type = watch("type"); // 職歴か学歴かを監視

    const onSubmit = (data: FormData) => {
        if (isCurrent) {
            data.endedAt = "現在"; // 現在を示す特別な値に置き換える
        }
        onSave(data);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{isEdit ? '職歴・学歴の変更' : '職歴・学歴の登録'}</DialogTitle>
                <DialogContent>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <FormControl component="fieldset">
                                <FormLabel component="legend">タイプ</FormLabel>
                                <RadioGroup {...field} row>
                                    <FormControlLabel value="education" control={<Radio />} label="学歴" />
                                    <FormControlLabel value="career" control={<Radio />} label="職歴" />
                                </RadioGroup>
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="organization"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                margin="dense"
                                label={type === 'career' ? '団体or会社名' : "学校名orプログラム名"}
                                type="text"
                                fullWidth
                                variant="outlined"
                                error={!!errors.organization}
                                helperText={errors.organization?.message}
                            />
                        )}
                    />
                    {type === 'career' && (
                        <>
                            <Controller
                                name="position"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        margin="dense"
                                        label="役割"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        error={!!errors.position}
                                        helperText={errors.position?.message}
                                    />
                                )}
                            />
                            <Controller
                                name="employeeType"
                                control={control}
                                render={({ field }) => (
                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        <InputLabel id="employee-type-label">雇用形態</InputLabel>
                                        <Select
                                            {...field}
                                            labelId="employee-type-label"
                                            label="雇用形態"
                                            error={!!errors.employeeType}
                                        >
                                            {EMPLOYEE_TYPE_OPTIONS.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        margin="dense"
                                        label="仕事内容や役割"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={!!errors.description}
                                        helperText={errors.description?.message}
                                    />
                                )}
                            />
                        </>
                    )}
                    {type === 'education' && (
                        <>
                            <Controller
                                name="major"
                                control={control}
                                rules={{ required: "勉強分野は必須です。" }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        margin="dense"
                                        label="勉強分野"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        error={!!error}
                                        helperText={error ? error.message : ""}
                                    />
                                )}
                            />
                            <Controller
                                name="learned"
                                control={control}
                                rules={{ required: "学んだことは必須です。" }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        margin="dense"
                                        label="学んだこと"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={!!error}
                                        helperText={error ? error.message : ""}
                                    />
                                )}
                            />
                        </>
                    )}

                    <Box sx={{ marginTop: 2 }}>
                        <Stack spacing={{ xs: 2, sm: 2 }} direction={{ xs: "column", sm: "row" }}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                // @ts-ignore
                                adapterLocale={ja}
                                dateFormats={{
                                    monthAndYear: 'YYYY年MM月',
                                    normalDate: 'YYYY年MM月DD日',
                                    keyboardDate: 'YYYY年MM月DD日',
                                }}
                                localeText={{
                                    previousMonth: '前へ',
                                    nextMonth: '次へ',
                                    // @ts-ignore
                                    cancelText: "キャンセル",
                                    okText: "選択",
                                }}
                            >
                                <Controller
                                    name="startedAt"
                                    control={control}
                                    rules={{ required: '開始日は必須です' }}
                                    render={({ field }) => (
                                        <DatePicker
                                            label="開始日"
                                            views={['year', 'month']}
                                            openTo="year"
                                            format="YYYY年MM月"
                                            value={field.value ? dayjs(field.value, 'YYYY-MM') : null}
                                            onChange={(newValue) => {
                                                const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM') : '';
                                                field.onChange(formattedDate);
                                            }}
                                            // @ts-ignore
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!errors.startedAt}
                                                    helperText={errors.startedAt?.message}
                                                />
                                            )}
                                            inputFormat="YYYY-MM"
                                            sx={{ mr: 2, width: { xs: "100%", sm: "50%" } }}
                                            DialogProps={{
                                                sx: {
                                                    '& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
                                                        fontSize: '1.5rem',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />

                                {!isCurrent && (
                                    <Controller
                                        name="endedAt"
                                        control={control}
                                        rules={{ required: '終了日は必須です' }}
                                        render={({ field }) => (
                                            <DatePicker
                                                label="終了日"
                                                views={['year', 'month']}
                                                openTo="year"
                                                format="YYYY年MM月"
                                                value={field.value ? dayjs(field.value, 'YYYY-MM') : null}
                                                onChange={(newValue) => {
                                                    const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM') : '';
                                                    field.onChange(formattedDate);
                                                }}
                                                // @ts-ignore
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={!!errors.endedAt}
                                                        helperText={errors.endedAt?.message}
                                                    />
                                                )}
                                                inputFormat="YYYY-MM"
                                                sx={{ width: { xs: "100%", sm: "50%" } }}
                                                DialogProps={{
                                                    sx: {
                                                        '& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
                                                            fontSize: '1.5rem',
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </LocalizationProvider>
                        </Stack>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <FormControlLabel
                                control={<Checkbox checked={isCurrent} onChange={e => setValue("isCurrent", e.target.checked)} />}
                                label="現在"
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {isEdit && (
                        <Button
                            startIcon={<DeleteIcon />}
                            onClick={onDelete}
                            color="error"
                        >
                            削除
                        </Button>
                    )}
                    <Button onClick={onClose}>キャンセル</Button>
                    <Button type="submit" color="primary" variant='contained'>
                        保存
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default HistoryEditDialog;