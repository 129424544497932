import React from 'react';
import { Box, Typography } from '@mui/material';

import {
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineItem
} from '@mui/lab';


import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import HistoryEditDialog from './HistoryEditDialog';

import { useUser } from '../../Context/UserContext';

import { useProfileModals } from '../../Hooks/useProfile';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

type CustomTimelineItemProps = {
    content: any;
    startedAt: string;
    endedAt: string;
    organization: string;
    type: string;
    position?: string;
    major?: string;
    description?: string;
    learned?: string;
    isDisabled: boolean;
};

const formatDate = (dateString: string): string => {
    return dateString.replace(/-/g, '/');
};

const getIconForType = (type: string) => {
    switch (type) {
        case 'education':
            return <SchoolIcon />;
        case 'career':
            return <BusinessIcon />;
        default:
            return null;
    }
};

const calculateTerm = (startedAt: string, endedAt: string): string => {
    const start = dayjs(startedAt);
    const end = endedAt === '現在' ? dayjs() : dayjs(endedAt);
    const diff = end.diff(start);
    const termDuration = dayjs.duration(diff);

    const years = termDuration.years() > 0 ? `${termDuration.years()}年` : '';
    const months = termDuration.months() > 0 ? `${termDuration.months()}ヶ月` : '';

    return `${years}${months}` || '期間不明';
};

const CustomTimelineItem: React.FC<CustomTimelineItemProps> = ({
    content,
    startedAt,
    endedAt,
    organization,
    type,
    position,
    major,
    description,
    learned,
    isDisabled,
}) => {
    const { user } = useUser();

    const term = calculateTerm(startedAt, endedAt);
    const icon = getIconForType(type);

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const {
        modalState,
        handleOpenModal,
        handleCloseModal,
        handleUpdateHistoryData,
        handleDeleteHistory,
    } = useProfileModals(user?.documentId);

    return (
        <>
            <TimelineItem
                onClick={(data) => {
                    if (!isDisabled) {
                        handleOpenModal(data, 'history')
                    }
                }}
                sx={{
                    cursor: isDisabled ? 'default' : 'pointer',
                    '&:hover': {
                        bgcolor: isDisabled ? 'transparent' : 'action.hover',
                    },
                    transition: 'background-color 0.3s',
                }}
            >
                {isSm && (
                    <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                        {formatDate(endedAt)}
                        <br />
                        ~{formatDate(startedAt)}
                    </TimelineOppositeContent>
                )}
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>{icon}</TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', pt: 2, borderBottom: '1px solid #ccc' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold' }}>
                            {organization}
                        </Typography>
                        {term && (
                            <Typography variant="body1" component="span" color="text.secondary" sx={{ fontWeight: 'bold', ml: 1 }}>
                                {term}
                            </Typography>
                        )}
                    </Box>
                    {type === 'career' && (
                        <>
                            {!isSm && <Typography variant="caption" color="text.secondary">{formatDate(endedAt)} ~ {formatDate(startedAt)}</Typography>}
                            {isSm && (
                                <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    {position}
                                </Typography>
                            )}
                        </>
                    )}
                    {type === 'education' && (
                        <>
                            {!isSm && <Typography variant="caption" color="initial">{formatDate(endedAt)} ~ {formatDate(startedAt)}</Typography>}
                            {isSm && (
                                <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    {major}
                                </Typography>
                            )}
                        </>
                    )}

                    {type === 'career' && (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {description}
                        </Typography>
                    )}
                    {type === 'education' && (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {learned}
                        </Typography>
                    )}
                </TimelineContent>
            </TimelineItem>

            <HistoryEditDialog
                open={modalState.type === 'history' && modalState.open}
                onClose={handleCloseModal}
                // onSave={(data) => updateHistoryData(data, user.documentId, content.id)}
                onSave={(data) => handleUpdateHistoryData(data, user.documentId, content.id)}
                onDelete={() => handleDeleteHistory(user.documentId, content.id)}
                initialData={content}
                isEdit={true}
            />
        </>
    );
};

type CustomTimelineProps = {
    items: any[];
    isDisabled: boolean;
};

const CustomTimeline: React.FC<CustomTimelineProps> = ({ items, isDisabled }) => {
    return (
        <>
            {items.map((item, index) => (
                <CustomTimelineItem key={index} content={item} {...item} isDisabled={isDisabled} />
            ))}
        </>
    );
};

export default CustomTimeline;