// components/Common/BookmarkButton.tsx
import { IconButton } from '@mui/material';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { updateBookmark } from '../../services/firebase/firestore';
import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';

interface BookmarkButtonProps {
    itemId: string;
    isBookmarked: boolean;
    onBookmarkToggle: () => void;
}

const BookmarkButton: React.FC<BookmarkButtonProps> = ({ itemId, isBookmarked, onBookmarkToggle }) => {
    const { user } = useUser();
    const { showSnackbar } = useSnackbar();

    const handleBookmark = async () => {
        if (!user) {
            return;
        }
        try {
            await updateBookmark(user.documentId, itemId, isBookmarked);
            onBookmarkToggle();
        } catch (error) {
            showSnackbar({
                message: "ブックマークの更新に失敗しました。",
                type: "error",
            });
        }
    };

    return (
        <IconButton
            size="large"
            sx={{ ml: 1, color: isBookmarked ? 'primary.main' : 'inherit' }} // ブックマーク済みの場合は色を変更
            onClick={(e) => {
                e.preventDefault(); // リロード防止
                handleBookmark();   // 非同期でブックマーク処理を実行
            }}
        >
            {isBookmarked ? <TurnedInIcon fontSize="inherit" /> : <TurnedInNotIcon fontSize="inherit" />}
        </IconButton>
    );
};

export default BookmarkButton;
