import { collection, query, orderBy, doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

export const getMessagesQuery = (activeChat: string) => {
    return query(collection(firestore, `chats/${activeChat}/messages`), orderBy('timestamps'));
};

export const getUserIcons = async (activeChat: string) => {
    const chatDocRef = doc(firestore, 'chats', activeChat);
    const chatDoc = await getDoc(chatDocRef);

    if (chatDoc.exists()) {
        const participantIds = chatDoc.data().participantIds;
        const userIconPromises = participantIds.map(async (uid: string) => {
            const userDocRef = doc(firestore, 'users', uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                return { uid, userIconUrl: userDoc.data().userIconUrl || 'null' };
            }
            return { uid, userIconUrl: 'default-icon-url.png' };
        });
        return await Promise.all(userIconPromises);
    }
    return [];
};
