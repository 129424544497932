// components/ResetPassword.tsx
import React from 'react';
import {
    Button,
    Container,
    TextField,
    Typography,
} from '@mui/material';

import { useDoResetPassword } from '../../Hooks/usePasswordReset';

import NavigationBar from '../../components/Organisms/NavigationBar';
import Footer from '../../components/Organisms/Footer';
import ResponsiveContainer from '../../components/Templates/ResponsiveContainer';

const ResetPassword: React.FC = () => {
    const {
        mode,
        password,
        confirmPassword,
        setPassword,
        setConfirmPassword,
        error,
        handlePasswordReset,
        handleEmailVerification,
    } = useDoResetPassword();

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <Container component="main" maxWidth="xs" sx={{ minHeight: '55vh' }}>
                    {mode === 'resetPassword' && (
                        <>
                            <Typography component="h1" variant="h5">パスワードをリセット</Typography>
                            <form onSubmit={(e) => { e.preventDefault(); handlePasswordReset(); }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="新しいパスワード"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={Boolean(error)}
                                    helperText={error}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="新しいパスワード（確認）"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={Boolean(error)}
                                    helperText={error}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}
                                >
                                    パスワードをリセット
                                </Button>
                            </form>
                        </>
                    )}

                    {mode === 'verifyAndChangeEmail' && (
                        <>
                            <Typography component="h1" variant="h5">メールアドレスの確認</Typography>
                            <Typography>メールアドレスを確認しています...</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleEmailVerification}
                                sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}
                            >
                                メールアドレスを確認
                            </Button>
                        </>
                    )}

                    {mode === '' && (
                        <Typography>無効なリクエストです。もう一度お試しください。</Typography>
                    )}
                </Container>
            </ResponsiveContainer>
            <Footer />
        </>
    );
};

export default ResetPassword;
