import React from 'react';
import { 
    Table, 
    TableBody, 
    TableRow, 
    TableCell, 
    Typography, 
    TextField, 
    Skeleton 
} from '@mui/material';

import { CORPORATE_VALUES } from '../../utils/options';

interface Value {
    valueId: string;
    description: string;
}

interface ValueTableProps {
    isLoading: boolean; // 追加
    hasEditPermission: boolean;
    companyValues: Value[];
    onValueChange: (index: number, key: string, value: string) => void;
}

const ValueTable: React.FC<ValueTableProps> = ({
    isLoading,  // 追加
    hasEditPermission,
    companyValues,
    onValueChange,
}) => {
    const safeCompanyValues = companyValues || [];

    // safeCompanyValues の長さが 3 未満の場合、不足分のダミーデータを追加
    const displayValues = safeCompanyValues.length >= 3 ? safeCompanyValues : [
        ...safeCompanyValues,
        ...Array(3 - safeCompanyValues.length).fill({}).map((_, i) => ({
            valueId: `none`,
            description: '',
        })),
    ];

    return (
        <Table>
            <TableBody>
                {displayValues.map((value, index) => (
                    <TableRow key={index}>
                        <TableCell sx={{ verticalAlign: 'middle', width: 200 }}>
                            {isLoading ? (
                                <Skeleton variant="text" width="100%" height={50} />
                            ) : hasEditPermission ? (
                                <TextField
                                    select
                                    label="価値観"
                                    value={value.valueId}
                                    onChange={(e) => onValueChange(index, 'valueId', e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    {CORPORATE_VALUES.map((option) => (
                                        <option key={option.valueId} value={option.valueId}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            ) : (
                                <Typography variant="h5">
                                    {CORPORATE_VALUES.find(v => v.valueId === value.valueId)?.label || ''}
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell>
                            {isLoading ? (
                                <Skeleton variant="rectangular" width="100%" height={100} />
                            ) : hasEditPermission ? (
                                <TextField
                                    value={value.description || ''}
                                    onChange={(e) => onValueChange(index, 'description', e.target.value)}
                                    multiline
                                    rows={6}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="入力してください"
                                />
                            ) : (
                                <Typography variant="body1">
                                    {value?.description || '---'}
                                </Typography>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ValueTable;
