// hooks/useResetPassword.ts
import { useState, useEffect } from 'react';
import { useSnackbar } from '../Context/SnackbarContext';
import {
    useForm,
    SubmitHandler
} from 'react-hook-form';
import {
    sendPasswordReset,
    verifyResetPasswordCode,
    resetPassword,
    verifyEmailActionCode
} from '../services/firebase/authService';
import { getQueryParams } from '../utils/urlHelpers';


interface FormValues {
    email: string;
}

/**
 * Custom hook for handling password reset logic
 */
export const usePasswordResetMail = (onClose: () => void) => {
    const { showSnackbar } = useSnackbar();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<FormValues>({
        mode: 'onChange',
    });

    const handleResetPassword: SubmitHandler<FormValues> = async ({ email }) => {
        try {
            await sendPasswordReset(email);
            showSnackbar({ message: '', type: 'success', title: 'パスワードリセットメールを送信しました。' });
            reset();
            onClose();
        } catch (error) {
            showSnackbar({ message: '再度お試しください', type: 'error', title: '送信に失敗' });
        }
    };

    return {
        register,
        handleSubmit,
        handleResetPassword,
        reset,
        errors,
        isValid,
    };
};


export const useDoResetPassword = () => {
    const [actionCode, setActionCode] = useState<string>('');
    const [mode, setMode] = useState<string>(''); // モードを管理するステート
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const { oobCode, mode } = getQueryParams();
        setActionCode(oobCode);
        setMode(mode);
    }, []);

    const handlePasswordReset = async () => {
        if (actionCode === '') return;
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            await verifyResetPasswordCode(actionCode);
            await resetPassword(actionCode, password);
            showSnackbar({ message: "パスワードを変更しました", type: 'success' });
            window.location.href = "/login";
        } catch (error: any) {
            showSnackbar({ message: error.message, type: 'error' });
        }
    };

    const handleEmailVerification = async () => {
        try {
            await verifyEmailActionCode(actionCode);
            showSnackbar({ message: "メールアドレスが確認され、変更されました", type: 'success' });
            window.location.href = "/login";
        } catch (error: any) {
            showSnackbar({ message: error.message, type: 'error' });
        }
    };

    return {
        mode,
        password,
        confirmPassword,
        setPassword,
        setConfirmPassword,
        error,
        handlePasswordReset,
        handleEmailVerification
    };
};
