// hooks/useAuth.ts
import { useState } from 'react';
import { loginWithEmail, signUpWithEmail, signInWithLine } from '../services/firebase/authService';
import { createUserInFirestore, getUserFromFirestore } from '../services/firebase/userService';
import { Timestamp } from 'firebase/firestore';
import { auth } from '../firebase';

export const useAuth = () => {
    const [error, setError] = useState<string | null>(null);

    const login = async (email: string, password: string, onSuccess: (user: any) => void) => {
        try {
            await loginWithEmail(email, password);
            const user = await getUserFromFirestore(auth.currentUser!.uid); // ログインしたユーザーを取得
            onSuccess(user);
        } catch (error: any) {
            setError(error.message);
        }
    };

    const signUp = async (data: any, onSuccess: () => void) => {
        try {
            const userCredential = await signUpWithEmail(data.email, data.password);
            const user = userCredential.user;

            const birthDateTimestamp = Timestamp.fromDate(new Date(data.birthDate));
            const userData = {
                email: data.email,
                phoneNumber: data.phoneNumber,
                lastName: data.lastName,
                firstName: data.firstName,
                birthDate: birthDateTimestamp,
                gender: data.gender,
                userType: data.userType,
                age: new Date().getFullYear() - new Date(data.birthDate).getFullYear(),
                emailNotification: 'notify',
                hasCatchphrase: false,
                hasProfile: false,
                hasGoals: false,
                hasAwards: false,
                hasCertifications: false,
                hasUsericonurl: false,
            };
            await createUserInFirestore(user.uid, userData);
            onSuccess();
        } catch (error: any) {
            setError(error.message);
        }
    };

    const signInWithLineProvider = async (onSuccess: () => void) => {
        try {
            // Ensure Storage Access API is handled
            if (document.hasStorageAccess && !await document.hasStorageAccess()) {
                await document.requestStorageAccess();
            }

            // Perform LINE sign-in
            const userCredential = await signInWithLine();
            const user = userCredential.user;

            // Fetch user from Firestore or create if it doesn't exist
            const userDoc = await getUserFromFirestore(user.uid);
            if (!userDoc) {
                await createUserInFirestore(user.uid, {
                    email: '',
                    phoneNumber: '',
                    userIconUrl: user.photoURL || '',
                    lastName: user.displayName ? user.displayName.split(' ')[0] : '',
                    firstName: '',
                    birthDate: '',
                    gender: 'other',
                    userType: 'other',
                    emailNotification: 'notify',
                    age: 16,
                    hasCatchphrase: false,
                    hasProfile: false,
                    hasGoals: false,
                    hasAwards: false,
                    hasCertifications: false,
                    hasUsericonurl: false,
                    providerId: 'line',
                });
            }

            // Ensure this is called after all async tasks are complete
            onSuccess();
        } catch (error: any) {
            setError(error.message);
        }
    };

    return { error, login, signUp, signInWithLineProvider };
};
