// React & ルーター関連
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// MUI コンポーネント & アイコン
import {
	Box,
	Button,
	Container,
	FormLabel,
	FormControl,
	FormControlLabel,
	Grid,
	InputAdornment,
	InputLabel,
	Link,
	MenuItem,
	RadioGroup,
	Radio,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EmailIconOutlined from '@mui/icons-material/EmailOutlined';
import LockIconOutlined from '@mui/icons-material/LockOutlined';
import PhoneIconOutlined from '@mui/icons-material/PhoneOutlined';

// dayjs
import dayjs, { Dayjs } from 'dayjs';
import ja from 'dayjs/locale/ja';

// カスタムコンポーネント & コンテキスト
import NavigationBar from '../../components/Organisms/NavigationBar';
import Footer from '../../components/Organisms/Footer';
import ResponsiveContainer from '../../components/Templates/ResponsiveContainer';

// ユーティリティ & カスタムフック
import { useAuth } from '../../Hooks/useAuth';
import { EDUCATION_OPTIONS } from '../../utils/options';

// 定義する型
interface FormData {
	email: string;
	password: string;
	phoneNumber: string;
	lastName: string;
	firstName: string;
	birthDate: Dayjs;
	gender: 'male' | 'female' | 'other';
	userType: string;
}

const StudentSignupForm: React.FC = () => {
	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm<FormData>({
		mode: "onChange",
		defaultValues: {
			birthDate: dayjs(), // ここでdayjsのデフォルト値を設定することもできます
		}
	});
	const { signUp, signInWithLineProvider } = useAuth();

	const onSubmit = (data: FormData) => {
		signUp(data, () => {
			navigate('/');
		});
	};

	const handleOIDCLogin = async () => {
		await signInWithLineProvider(() => {
			navigate('/');
		});
	}

	return (
		<>
			<NavigationBar />
			<ResponsiveContainer>
				<Container component="main" maxWidth="xs">
					<Typography variant="h5" component="h1" gutterBottom>アカウント登録</Typography>
					<form onSubmit={handleSubmit(onSubmit)}>
						<TextField
							label="メールアドレス"
							variant="outlined"
							type="email"
							fullWidth
							margin="normal"
							{...register("email", { required: "メールアドレスは必須です", pattern: /^\S+@\S+\.\S+$/ })}
							error={!!errors.email}
							helperText={errors.email?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EmailIconOutlined />
									</InputAdornment>
								),
							}}
						/>

						<TextField
							label="パスワード"
							variant="outlined"
							type="password"
							fullWidth
							margin="normal"
							{...register("password", { required: "パスワードは必須です", minLength: { value: 6, message: "パスワードは6文字以上である必要があります" } })}
							error={!!errors.password}
							helperText={errors.password?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockIconOutlined />
									</InputAdornment>
								),
							}}
						/>

						<TextField
							label="電話番号"
							variant="outlined"
							fullWidth
							type="tel"
							margin="normal"
							{...register("phoneNumber", {
								required: "電話番号は必須です",
								pattern: {
									value: /^(\+?\d[- .]*){7,13}$/,
									message: "無効な電話番号です"
								}
							})}
							error={!!errors.phoneNumber}
							helperText={errors.phoneNumber?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PhoneIconOutlined />
									</InputAdornment>
								),
							}}
						/>

						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									label="苗字"
									variant="outlined"
									fullWidth
									type="text"
									margin="normal"
									{...register("lastName", { required: "苗字は必須です" })}
									error={!!errors.lastName}
									helperText={errors.lastName?.message}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="名前"
									variant="outlined"
									type="text"
									fullWidth
									margin="normal"
									{...register("firstName", { required: "名前は必須です" })}
									error={!!errors.firstName}
									helperText={errors.firstName?.message}
								/>
							</Grid>
						</Grid>

						<FormControl fullWidth margin="normal">
							<InputLabel id="userType-label">種別</InputLabel>
							<Controller
								name="userType"
								control={control}
								rules={{ required: "種別を選択してください" }}
								render={({ field }) => (
									<Select
										labelId="userType-label"
										id="userType"
										label="種別"
										{...field}
									>
										<MenuItem value="">
											<em>未選択</em>
										</MenuItem>
										{EDUCATION_OPTIONS.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors.userType && <Typography color="error">{errors.userType.message}</Typography>}
						</FormControl>

						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							// @ts-ignore: エラーを一時的に無視
							adapterLocale={ja}
							dateFormats={{
								monthAndYear: 'YYYY年MM月',
								normalDate: 'YYYY年MM月DD日',
								keyboardDate: 'YYYY年MM月DD日',
							}}
							localeText={{
								previousMonth: '前へ',
								nextMonth: '次へ',
								// @ts-ignore: エラーを一時的に無視
								cancelText: "キャンセル",
								okText: "選択",
							}}
						>
							<FormControl component="fieldset" margin="normal" fullWidth>
								<Controller
									name="birthDate"
									control={control}
									rules={{ required: "生年月日は必須です" }}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<DatePicker
											views={['year', 'month', 'day']}
											openTo="year"
											format="YYYY年MM月DD日"
											label="生年月日"
											value={value}
											onChange={onChange}
											// @ts-ignore: エラーを一時的に無視
											renderInput={(params) => (
												<TextField
													{...params}
													error={!!error}
													helperText={error ? error.message : null}
													fullWidth
													margin="normal"
												/>
											)}
											DialogProps={{
												sx: {
													'& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
														fontSize: '1.5rem',
													},
												},
											}}
										/>
									)}
								/>
							</FormControl>
						</LocalizationProvider>

						<FormControl component="fieldset" margin="normal" fullWidth>
							<FormLabel component="legend">性別</FormLabel>
							<Controller
								name="gender"
								control={control}
								rules={{ required: "性別を選択してください" }}
								render={({ field }) => (
									<RadioGroup {...field} row>
										<FormControlLabel value="male" control={<Radio />} label="男性" />
										<FormControlLabel value="female" control={<Radio />} label="女性" />
										<FormControlLabel value="other" control={<Radio />} label="その他" />
									</RadioGroup>
								)}
							/>
							{errors.gender && <Typography color="error">{errors.gender.message}</Typography>}
						</FormControl>

						<Typography variant="body2">
							登録することで、<Link href="https://www.cielinc.co.jp/terms" target="_blank" style={{ textDecoration: 'none' }}>利用規約</Link>および<Link href="https://www.cielinc.co.jp/privacy-policy" target="_blank" style={{ textDecoration: 'none' }}>プライバシーポリシー</Link>に同意したものとします。
						</Typography>

						<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
							<Button type="submit" variant="contained" color="primary" fullWidth size="large" sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2, fontWeight: 'bold' }}>
								アカウント登録
							</Button>
						</Box>
					</form>

					<Button
						variant='contained'
						onClick={handleOIDCLogin}
						size="large"
						fullWidth
						sx={{
							borderRadius: '8px',
							paddingY: 1.5,
							marginTop: 2,
							backgroundColor: '#06C755',
							fontWeight: 'bold',
							'&:hover': {
								backgroundColor: 'rgba(6, 199, 85, 0.9)',
								boxShadow: 'none',
							},
							'&:active': {
								backgroundColor: 'rgba(6, 199, 85, 0.7)',
								boxShadow: 'none',
							}
						}}
					>
						LINEでサインアップ
					</Button>
					<Typography variant="body2" sx={{ mt: 2 }}>LINEログインは一般ユーザーのみ利用できます</Typography>
				</Container>
			</ResponsiveContainer>
			<Footer />
		</>
	);
};

export default StudentSignupForm;
