// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyArvhiIsdeZdW24XoyqWXgCN1tardskRjA",
  authDomain: "ciel-dac23.firebaseapp.com",
  projectId: "ciel-dac23",
  storageBucket: "ciel-dac23.appspot.com",
  messagingSenderId: "512068407772",
  appId: "1:512068407772:web:4ba21ab09a77755fccff19",
  measurementId: "G-YR49JQEE57"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

// ローカル開発環境でのみEmulatorに接続
// if (window.location.hostname === "127.0.0.1") {
//   // Auth Emulatorへの接続
//   connectAuthEmulator(auth, "http://127.0.0.1:9099");

//   // Firestore Emulatorへの接続
//   connectFirestoreEmulator(firestore, "127.0.0.1", 8080);

//   // Storage Emulatorへの接続
//   connectStorageEmulator(storage, "127.0.0.1", 9199);

//   // AnalyticsはEmulatorがないため設定不要
// }
