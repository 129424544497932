import React from 'react';
import {
    Box,
    Typography,
    Link,
    Grid,
    Container
} from '@mui/material';

import { EXTERNAL_LINKS } from '../../config/externalLinks';
import Logo from '../../assets/images/ciel_logo_with_text.webp';

const Footer: React.FC = () => {
    return (
        <Box component="footer" sx={{ mt: 12, pt: 12, pb: 3, px: 2, backgroundColor: '#F4F5F6' }}>
            <Container sx={{ maxWidth: '960px' }}>
                <Grid container spacing={2} justifyContent="center" sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: { xs: 0, md: 14 },
                    flexDirection: { xs: 'column-reverse', md: 'row' },
                    alignItems: 'center'
                }}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <img src={Logo} alt="logo" style={{ width: '250px', height: 'auto' }} />
                            <Typography variant="body1" sx={{ textAlign: 'left', display: { xs: 'none', md: 'block' } }}>
                                株式会社Ciel (Ciel Inc.) <br />
                                〒996-0023 <br />
                                山形県新庄市沖の町７‐２４
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#1976d2', mb: 1, textAlign: 'left' }}>
                                    製品について
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 1 }}>
                                    <Link href="/" color="inherit" sx={{ textDecoration: 'none' }}>ホーム</Link>
                                    <Link href="/chat/null" color="inherit" sx={{ textDecoration: 'none' }}>チャット</Link>
                                    <Link href={EXTERNAL_LINKS.terms} color="inherit" sx={{ textDecoration: 'none' }}>利用規約</Link>
                                    <Link href={EXTERNAL_LINKS.privacyPolicy} color="inherit" sx={{ textDecoration: 'none' }}>プライバシーポリシー</Link>
                                    <Link href={EXTERNAL_LINKS.law} color="inherit" sx={{ textDecoration: 'none' }}>特定商取引法に基づく表記</Link>
                                </Box>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#1976d2', mb: 1, textAlign: 'left' }}>
                                    運営会社
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 1 }}>
                                    <Link href={EXTERNAL_LINKS.home} color="inherit" sx={{ textDecoration: 'none' }}>サービスホーム</Link>
                                    <Link href={EXTERNAL_LINKS.company} color="inherit" sx={{ textDecoration: 'none' }}>会社概要</Link>
                                    <Link href={EXTERNAL_LINKS.news} color="inherit" sx={{ textDecoration: 'none' }}>お知らせ</Link>
                                    <Link href={EXTERNAL_LINKS.contact} color="inherit" sx={{ textDecoration: 'none' }}>お問い合わせ</Link>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Typography variant="body2" sx={{ mt: 3, textAlign: 'center', color: 'text.secondary' }}>
                    © {new Date().getFullYear()} Ciel Inc. All Rights Reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;