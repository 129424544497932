interface FirebaseErrorMap {
    [key: string]: string;
}

export function getFirebaseErrorMessage({ defaultErrorMessage, errorCode }: { defaultErrorMessage: string; errorCode: string }): string {
    const errorMessages: FirebaseErrorMap = {
        // createUserWithEmailAndPassword errors
        'auth/email-already-in-use': '指定されたメールアドレスを持つアカウントが既に存在します。',
        'auth/operation-not-allowed': 'メール/パスワード アカウントが有効でないです。Firebase コンソールで有効にしてください。',
        'auth/weak-password': 'パスワードが十分に強くありません。',

        // signInWithEmailAndPassword errors
        'auth/user-disabled': 'このユーザーは無効になっています。',
        'auth/user-not-found': 'ユーザーが見つかりません。アカウントが存在しない場合は、新規登録をしてください。',
        'auth/wrong-password': 'パスワードが間違っています。',

        // General errors
        'auth/invalid-email': '無効なメールアドレスです。',
    };

    // 指定されたエラーメッセージが存在すればそれを返し、なければデフォルトのエラーメッセージを返す
    return errorMessages[errorCode] || defaultErrorMessage;
}