import React, { useState } from 'react';
import { Box, IconButton, TextField, Stack, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { useUser } from '../../Context/UserContext';

interface MessageInputFieldProps {
    onSend: (message: string) => void;
}

export const MessageInputField: React.FC<MessageInputFieldProps> = ({ onSend }) => {
    const [message, setMessage] = useState<string>('');
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const handleSend = () => {
        if (message.trim() !== '') {
            onSend(message);
            setMessage('');
        }
    };

    return (
        <Stack direction="row" spacing={1}  sx={{
            backgroundColor: 'grey.200',
            borderRadius: message.split('\n').length > 1 ? '25px' : '50px',
            alignItems: message.split('\n').length > 1 ? 'flex-end' : 'center',
            padding: '5px'
        }}>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="メッセージを入力..."
                multiline
                value={message}
                size={isSm ? 'medium' : 'small'}
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '25px',
                        padding: '8px 16px',
                        backgroundColor: 'grey.200',
                        color: 'grey.800',
                        '& fieldset': {
                            border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                    },
                }}
            />
            <IconButton
                onClick={handleSend}
                disabled={!message.trim()}
                sx={{
                    backgroundColor: 'grey.900',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'grey.900',
                    },
                    borderRadius: '50%',
                }}
                size={'small'}
            >
                <SendIcon />
            </IconButton>
        </Stack>
    );
};

interface QuestionSuggestionsProps {
    onSend: (message: string) => void;
}

export const QuestionSuggestions: React.FC<QuestionSuggestionsProps> = ({ onSend }) => {
    const { user } = useUser();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const questions = [
        "どんな会社ですか？",
        "主な事業内容を教えてください。",
        "新卒採用はありますか？",
        "インターンシップの機会はありますか？",
    ];

    return (
        <Box sx={{ mr: 1 }}>
            {user?.userType !== 'company' && (
                <Stack
                    spacing={1}
                    direction={isSm ? 'row' : 'row'}
                    sx={{
                        mb: 2,
                        justifyContent: 'flex-end',
                        flexWrap: 'wrap',
                    }}
                >
                    {questions.map((question, index) => (
                        <Button
                            variant="outlined"
                            key={question}
                            onClick={() => onSend(question)}
                            sx={{ borderRadius: '25px' }}
                            className={index === 0 ? 'first-question-btn' : ''}
                        >
                            {question}
                        </Button>
                    ))}
                </Stack>
            )}
        </Box>
    );
};
