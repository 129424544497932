// helpers/formHelpers.ts

interface Language {
    lang: string;
    level: string;
}

interface FormData {
    [key: string]: any;
}

export const cleanFormData = (data: FormData): FormData => {
    const cleanedData: FormData = {};
    Object.keys(data).forEach((key) => {
        if (data[key] !== undefined) {
            cleanedData[key] = data[key];
        }
    });
    return cleanedData;
};

export const checkLanguagesChanged = (initialLanguages: Language[], currentLanguages: Language[]): boolean => {
    initialLanguages = initialLanguages || [];
    currentLanguages = currentLanguages || [];

    if (initialLanguages.length !== currentLanguages.length) {
        return true;
    }
    for (let i = 0; i < initialLanguages.length; i++) {
        if (initialLanguages[i].lang !== currentLanguages[i].lang || initialLanguages[i].level !== currentLanguages[i].level) {
            return true;
        }
    }
    return false;
};